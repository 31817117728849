import React from 'react';
import styled from 'styled-components';

import PageTitle from '@/components/Title/PageTitle';
import Breadcrumbs from '@/components/Breadcrumbs';
import { BreadcrumbType } from '@/components/Breadcrumbs/Breadcrumbs';
import useSettingItem from '@/hooks/useSettingItem';

type Props = {
  itemProp?: boolean;
  title?: string;
  note?: string | number;
  isNoteAtTop?: boolean;
  breadcrumbs?: Array<BreadcrumbType>;
};

function PageHeader({
  title,
  itemProp,
  note,
  breadcrumbs,
  isNoteAtTop,
}: Props) {
  const siteName = useSettingItem('WEBSITE_NAME');
  if (!title && !breadcrumbs) {
    return null;
  }
  const BREADCRUMB_LIST: Array<BreadcrumbType> = [];

  if (siteName) {
    BREADCRUMB_LIST.push({
      label: siteName,
      to: '/',
    });
  }

  if (breadcrumbs) {
    BREADCRUMB_LIST.push(...breadcrumbs);
  }

  return (
    <Container>
      {breadcrumbs ? <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} /> : null}
      {title ? (
        breadcrumbs ? (
          <TitleWrapper>
            <PageTitle
              itemProp={itemProp}
              title={title}
              note={note}
              isNoteAtTop={isNoteAtTop}
            />
          </TitleWrapper>
        ) : (
          <PageTitle title={title} note={note} isNoteAtTop={isNoteAtTop} />
        )
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  margin: 20px 0 30px;
`;

const TitleWrapper = styled.div`
  margin-top: 20px;
`;

export default PageHeader;
