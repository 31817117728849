import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

type Props = {
  text?: string;
  height?: number;
  className?: string;
};

function DisabledFeature({ text, height, className }: Props) {
  return (
    <Content className={className} height={height}>
      <Text>{text}</Text>
    </Content>
  );
}

const Content = styled.div<{ height?: number }>`
  background: ${colors.gray100};
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  overflow: hidden;
  padding: 20px;
  min-height: ${(props) => `${props.height}px`};
`;

const Text = styled.span`
  font-size: 30px;
  font-weight: 500;
`;

export default DisabledFeature;
