import React from 'react';
import styled from 'styled-components';

import HomeSeo from '@/modules/Home/HomeProject/components/HomeSeo';
import { colors } from '@/constants/theme';

import SamovarkinHomeBanners from './components/SamovarkinHomeBanners';
import SamovarkinHomeCatalog from './components/SamovarkinHomeCatalog';
import SamovarkinHomeProducts from './components/SamovarkinHomeProducts';
import SamovarkinHomeCards from './components/SamovarkinHomeCards';

function SamovarkinHome() {
  return (
    <Container>
      <SamovarkinHomeBanners />
      <SamovarkinHomeCatalog />
      <SamovarkinHomeProducts />
      <SamovarkinHomeCards />
      <SeoWrapper>
        <HomeSeo />
      </SeoWrapper>
    </Container>
  );
}

const Container = styled.div``;

const SeoWrapper = styled.div`
  margin: 60px 0;
  padding-top: 50px;
  border-top: 1px solid ${colors.gray100};
`;

export default SamovarkinHome;
