import React from 'react';
import styled from 'styled-components';

import { ContactsPageType } from '@/typings/model';
import useCurrentPage from '@/hooks/useCurrentPage';
import ContentContainer from '@/components/ContentContainer';
import { BreadcrumbType } from '@/components/Breadcrumbs/Breadcrumbs';
import PageHeader from '@/components/PageHeader/PageHeader';

function ContactsTitleSection() {
  const page = useCurrentPage<ContactsPageType>();
  if (!page) return null;

  const BREADCRUMB_LIST: Array<BreadcrumbType> = [];

  if (page) {
    BREADCRUMB_LIST.push({
      label: page.title,
      to: page.path,
    });
  }

  return (
    <ContentContainer>
      <PageHeader title={page.title} breadcrumbs={BREADCRUMB_LIST} />
      {page.body ? (
        <Text dangerouslySetInnerHTML={{ __html: page.body }} />
      ) : null}
    </ContentContainer>
  );
}

const Text = styled.div`
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;
`;

export default ContactsTitleSection;
