import React from 'react';

import { Page } from '@tager/web-components';

import NotFound from '@/modules/NotFound';
import useSettingItem from '@/hooks/useSettingItem';
import Layout from '@/components/Layout';

function NotFoundPage() {
  const siteName = useSettingItem('WEBSITE_NAME');
  return (
    <Page title={siteName + ' - Страница не найдена'}>
      <Layout>
        <NotFound />
      </Layout>
    </Page>
  );
}

export default NotFoundPage;
