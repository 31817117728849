import React from 'react';
import styled from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import CreditOptionsTable from '@/components/CreditOptionsTable';
import useSettingItem from '@/hooks/useSettingItem';

function RassrochkaOptionsSection() {
  const creditPrices = useSettingItem('PRICES_CREDIT');

  return (
    <ContentContainer>
      <Content>
        <CreditOptionsTable options={creditPrices} />
      </Content>
    </ContentContainer>
  );
}

const Content = styled.div`
  width: 910px;
`;

export default RassrochkaOptionsSection;
