type CalculatePaymentProps = {
  price: number;
  initial?: number;
  period?: number;
  underPayment?: number;
};

export function calculatePayment({
  price,
  initial,
  underPayment,
  period,
}: CalculatePaymentProps) {
  if (
    typeof initial !== 'number' ||
    typeof underPayment !== 'number' ||
    typeof period !== 'number' ||
    price < initial
  ) {
    return {
      total: price,
      additional: 0,
      month: 0,
    };
  }

  const additional = ((price - initial) / 100) * underPayment;
  const month = (price + additional - initial) / period;

  return {
    total: price + additional,
    additional: additional,
    month: month,
  };
}
