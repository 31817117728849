import React from 'react';
import styled from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import { SamovarkinHomePageType } from '@/typings/model';
import ContentContainer from '@/components/ContentContainer';
import Link from '@/components/Link';

import { Title } from '../SamovarkinHome.styles';

import SamovarkinHomeContentCard from './components/SamovarkinHomeContentCard';

function SamovarkinHomeCards() {
  const page = useCurrentPage<SamovarkinHomePageType>();
  const templateFields = page?.templateFields;
  return (
    <ContentContainer>
      {templateFields?.contentBlocks?.map((content, index) => (
        <Container key={index}>
          <Title>{content.title}</Title>
          <Wrapper>
            {content?.items?.slice(0, 3).map((item, index) => (
              <ContentCardWrapper key={index}>
                {item.link ? (
                  <Link to={item.link}>
                    <SamovarkinHomeContentCard {...item} />
                  </Link>
                ) : (
                  <SamovarkinHomeContentCard {...item} />
                )}
              </ContentCardWrapper>
            ))}
          </Wrapper>
        </Container>
      ))}
    </ContentContainer>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
`;

const Wrapper = styled.div`
  margin: 25px -5px 0;
  display: flex;
`;

const ContentCardWrapper = styled.div`
  margin: 0 5px;
  flex-basis: calc(33.333% - 10px);
`;

export default SamovarkinHomeCards;
