import React from 'react';
import styled from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import TextHtml from '@/components/TextHTML';
import ContentContainer from '@/components/ContentContainer';
import PageHeader from '@/components/PageHeader';

function DefaultTemplate() {
  const page = useCurrentPage();

  return (
    <ContentContainer>
      <Page>
        <PageHeader title={page?.title} />
        <TextHtml text={page?.body ?? ''} />
      </Page>
    </ContentContainer>
  );
}

const Page = styled.div`
  margin-bottom: 60px;
`;

export default DefaultTemplate;
