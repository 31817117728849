import React from 'react';

import ContentContainer from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { PaymentPageType } from '@/typings/model';
import TextHtml from '@/components/TextHTML';

import { TitleWrapper, Title, Note } from '../Payment.style';

function PaymentEripSection() {
  const page = useCurrentPage<PaymentPageType>();
  const pageFields = page?.templateFields;
  return (
    <ContentContainer>
      <TitleWrapper>
        <Title>{pageFields?.eripTitle}</Title>
        <Note>{pageFields?.eripNote}</Note>
      </TitleWrapper>
      {pageFields?.eripContent ? (
        <TextHtml text={pageFields.eripContent} />
      ) : null}
    </ContentContainer>
  );
}

export default PaymentEripSection;
