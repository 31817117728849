import React from 'react';
import styled, { css } from 'styled-components';

import { SvgComponent } from '@tager/web-components';
import { Nullable } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { ReactComponent as WalletIcon } from '@/assets/svg/payment_wallet.svg';
import { ReactComponent as CardsIcon } from '@/assets/svg/payment_cards.svg';
import { ReactComponent as InvoiceIcon } from '@/assets/svg/payment_invoice.svg';
import { ReactComponent as CreditIcon } from '@/assets/svg/payment_credit.svg';
import { ReactComponent as CreditCardIcon } from '@/assets/svg/payment_credt_cards.svg';
import { ReactComponent as MonitorIcon } from '@/assets/svg/payment_monitor.svg';
import { PaymentIconType } from '@/typings/common';
import { PaymentMethodCardType } from '@/typings/model';

function getIconComponent(
  icon: Nullable<PaymentIconType>
): Nullable<SvgComponent> {
  switch (icon) {
    case 'wallet':
      return WalletIcon;
    case 'card':
      return CardsIcon;
    case 'invoice':
      return InvoiceIcon;
    case 'credit':
      return CreditIcon;
    case 'cards':
      return CreditCardIcon;
    case 'monitor':
      return MonitorIcon;
    default:
      return null;
  }
}

type Props = PaymentMethodCardType & {
  className?: string;
};

function PaymentMethodCard({ className, ...card }: Props) {
  const { icon, title, description, button } = card;
  const IconComponent = getIconComponent(icon);

  function handleClick() {
    if (!button?.link) return;

    const domElement = document.querySelector(button?.link);
    if (domElement) {
      window.scroll({
        top: domElement.getBoundingClientRect().top + window.pageYOffset - 80,
        behavior: 'smooth',
      });
    }
  }

  return (
    <Cell className={className}>
      <Container onClick={handleClick} isClickable={Boolean(button?.link)}>
        <IconWrapper>{IconComponent ? <IconComponent /> : null}</IconWrapper>
        <Content>
          <Title>{title}</Title>
          <Text>{description}</Text>
        </Content>
        {button ? <Button>{button.label}</Button> : null}
      </Container>
    </Cell>
  );
}

const Cell = styled.div``;

const IconWrapper = styled.div`
  svg {
    height: 92px;
    width: 92px;
    margin-right: 30px;
    fill: ${colors.primary};
  }
`;

const Content = styled.div`
  flex: 1;
`;

const Title = styled.span`
  font-size: 24px;
  line-height: 36px;
  display: block;
  font-weight: 500;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 24px;
  margin-top: 5px;
`;

const Button = styled.button`
  position: absolute;
  right: 0;
  bottom: 0;
  background: ${colors.primary};
  color: ${colors.white};
  line-height: 30px;
  padding: 0 20px;
  font-size: 13px;
`;

const hoverMixin = css`
  cursor: pointer;
  &:hover {
    opacity: 0.85;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  &:hover ${Title} {
    color: ${colors.primary};
    transition: 0.3s;
  }
`;

const Container = styled.div<{ isClickable: boolean }>`
  height: 100%;
  overflow: hidden;
  background: ${colors.white};
  padding: 35px 30px;
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid ${colors.gray100};
  transition: 0.3s;
  ${(props) => (props.isClickable ? hoverMixin : '')};
  &:hover ${Button} {
    background: ${colors.primary100};
  }
`;

export default PaymentMethodCard;
