import React from 'react';
import styled from 'styled-components';

import FaqTitleSection from './components/FaqTitleSection';
import FaqQuestionsSection from './components/FaqQuestionsSection';
import FaqConsultantSection from './components/FaqConsultantSection';

function Faq() {
  return (
    <Container>
      <FaqTitleSection />
      <Wrapper>
        <FaqQuestionsSection />
      </Wrapper>
      <Wrapper>
        <FaqConsultantSection />
      </Wrapper>
    </Container>
  );
}

const Container = styled.div``;

const Wrapper = styled.div`
  margin-bottom: 65px;
`;

export default Faq;
