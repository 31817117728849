import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { PlainPicture } from '@/components/Picture';
import { DocumentCardType } from '@/typings/model';
import Placeholder from '@/assets/images/placeholder.png';

type Props = DocumentCardType & {
  onClick?: () => void;
};

function DocumentCard({ file, caption, onClick }: Props) {
  return (
    <Content onClick={onClick}>
      <PictureWrapper>
        {file ? (
          <PlainPicture
            {...convertThumbnailToPictureProps(file.cover)}
            alt={caption ?? 'photo document'}
          />
        ) : (
          <PlainPicture src={Placeholder} />
        )}
      </PictureWrapper>
      {caption ? <Title>{caption}</Title> : null}
    </Content>
  );
}

const Title = styled.span`
  display: block;
  font-size: 13px;
  color: ${colors.primary};
  font-weight: 500;
  margin-top: 15px;
  text-align: center;
`;

const hoverMixin = css`
  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    opacity: 0.85;
    cursor: pointer;
  }
  &:hover ${Title} {
    color: ${colors.primary100};
  }
`;

const Content = styled.div`
  min-height: 295px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  transition: 0.3s;
  border: 1px solid ${colors.gray100};
  cursor: default;

  ${(props) => (props.onClick ? hoverMixin : '')};
`;

const PictureWrapper = styled.div`
  img {
    min-height: 200px;
  }
`;

export default DocumentCard;
