import React from 'react';
import styled, { css } from 'styled-components';

import { isNotNullish } from '@tager/web-core';

import { ReactComponent as SummaryCalcIcon } from '@/assets/svg/summary_calc.svg';
import { ReactComponent as BankCalcIcon } from '@/assets/svg/bank_calc.svg';
import { ReactComponent as CreditCardCalcIcon } from '@/assets/svg/credit_card_calc.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { normalizePrice } from '@/utils/common';

type Props = {
  total?: number;
  add?: number;
  monthPayment?: number;
};

function InstallmentTermsBlock({ total, add, monthPayment }: Props) {
  return (
    <Container>
      {isNotNullish(total) ? (
        <Cell>
          <IconWrapper>
            <SummaryCalcIcon />
          </IconWrapper>
          <Content>
            <ResultCellTitle>Итоговая сумма</ResultCellTitle>
            <ResultCellValue>{normalizePrice(total)} руб.</ResultCellValue>
          </Content>
        </Cell>
      ) : null}
      {isNotNullish(add) ? (
        <Cell>
          <IconWrapper>
            <BankCalcIcon />
          </IconWrapper>
          <Content>
            <ResultCellTitle>Переплата</ResultCellTitle>
            <ResultCellValue>{normalizePrice(add)} руб.</ResultCellValue>
          </Content>
        </Cell>
      ) : null}
      {isNotNullish(monthPayment) ? (
        <Cell>
          <IconWrapper>
            <CreditCardCalcIcon />
          </IconWrapper>
          <Content>
            <ResultCellTitle>Ежемесячный платеж</ResultCellTitle>
            <ResultCellValue bold>
              {normalizePrice(monthPayment)} руб.
            </ResultCellValue>
          </Content>
        </Cell>
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 25px 25px;
  border-radius: 5px;
  background: ${colors.primary};
  color: ${colors.white};
  ${media.mobileLarge(css`
    flex-direction: column;
  `)}
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
  flex: 1;
`;

const IconWrapper = styled.div`
  flex-basis: 35px;
  margin-right: 15px;
`;

const Content = styled.div``;

const ResultCellTitle = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 600;
`;

const ResultCellValue = styled.span<{ bold?: boolean }>`
  display: block;
  white-space: nowrap;
  font-size: ${(props) => (props.bold ? '24px' : '20px')};
  font-weight: ${(props) => (props.bold ? 600 : 400)};
  margin-top: 5px;
`;

export default InstallmentTermsBlock;
