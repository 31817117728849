import React from 'react';
import styled from 'styled-components';

import Link from '@/components/Link';
import ContentContainer from '@/components/ContentContainer';
import ContactsBlock from '@/components/ContactsBlock';
import PhoneNumber from '@/components/PhoneNumber';
import ContactsNote from '@/components/ContactsNote';
import Messengers from '@/components/Messengers';
import useSettingItem from '@/hooks/useSettingItem';

function ContactsMessengersSection() {
  const phoneNumberA1 = useSettingItem('CONTACTS_PHONE_A1');
  const phoneNumberMts = useSettingItem('CONTACTS_PHONE_MTS');
  const contactsEmail = useSettingItem('CONTACTS_EMAIL');
  const telegram = useSettingItem('CONTACTS_TELEGRAM');
  const whatsapp = useSettingItem('CONTACTS_WHATSAPP');
  const viber = useSettingItem('CONTACTS_VIBER');
  return (
    <ContentContainer>
      <Wrapper>
        <Inner>
          <ContactsBlock icon="phone">
            <Phone>
              <PhoneNumber
                operator="a1"
                phoneNumber={phoneNumberA1 ?? ''}
                variant="big"
              />
            </Phone>
            <Phone>
              <PhoneNumber
                operator="mts"
                phoneNumber={phoneNumberMts ?? ''}
                variant="big"
              />
            </Phone>
            <ContactsNote text="Ответим на вопросы с 09:00 до 21:00, без выходных" />
          </ContactsBlock>
        </Inner>
        <Inner>
          <ContactsBlock icon="mail">
            <StyledLink to={`mailto:${contactsEmail}`}>
              {contactsEmail}
            </StyledLink>
          </ContactsBlock>
        </Inner>
        <Inner>
          <Messengers
            text="При заказе через месенджеры"
            textBold="скидка 2%"
            telegram={telegram ?? ''}
            whatsapp={whatsapp ?? ''}
            viber={viber ?? ''}
          />
        </Inner>
      </Wrapper>
    </ContentContainer>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
`;

const Inner = styled.div`
  &:first-child {
    flex-basis: 470px;
  }
  flex-basis: 320px;
`;

const Phone = styled.div`
  margin-top: 15px;
  &:first-child {
    margin-top: 0;
  }
`;

const StyledLink = styled(Link)`
  font-size: 24px;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

export default ContactsMessengersSection;
