import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Placeholder from '@/assets/images/placeholder.png';
import { SamovarkinHomePageType, SliderSamovarkinType } from '@/typings/model';
import useCurrentPage from '@/hooks/useCurrentPage';
import { colors, fonts } from '@/constants/theme';
import ContentContainer from '@/components/ContentContainer';
import { PlainPicture } from '@/components/Picture';
import Link from '@/components/Link';
import Button from '@/components/Button';
import SwiperBullets from '@/modules/Home/HomeProject/components/SwiperBullets';

type Props = {
  slider: SliderSamovarkinType;
};

function Slider({ slider }: Props) {
  return (
    <SliderWrapper>
      {slider.image ? (
        <MainStyledPlainPicture
          {...convertThumbnailToPictureProps(slider.image)}
        />
      ) : (
        <MainStyledPlainPicture src={Placeholder} />
      )}
      <ContentContainer>
        <InfoWrapper>
          {slider.title ? <Title>{slider.title}</Title> : null}
          {slider.text ? <Description>{slider.text}</Description> : null}
          {slider.buttonLink ? (
            <Link to={slider.buttonLink}>
              <StyledButton variant="contained-primary-w100">
                {slider.buttonLabel ?? 'Подробнее'}
              </StyledButton>
            </Link>
          ) : null}
        </InfoWrapper>
      </ContentContainer>
    </SliderWrapper>
  );
}

function SamovarkinHomeBanners() {
  const page = useCurrentPage<SamovarkinHomePageType>();
  const templateFields = page?.templateFields;
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const innerRef = useRef<HTMLDivElement>(null);
  const sliderPagination = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <ContentContainer>
      <Container ref={innerRef}>
        <Inner>
          {isMounted ? (
            <SwiperBullets>
              {templateFields?.slider && !!templateFields?.slider.length
                ? templateFields?.slider.map((slider, index) => (
                    <SwiperSlide key={index}>
                      <Slider slider={slider} />
                    </SwiperSlide>
                  ))
                : null}
            </SwiperBullets>
          ) : (
            <>
              {templateFields?.slider &&
              !!templateFields?.slider.length &&
              templateFields?.slider[0].image ? (
                <Slider slider={templateFields.slider[0]} />
              ) : null}
            </>
          )}
          <ItemsPagination
            className="swiper-pagination"
            ref={sliderPagination}
          />
        </Inner>
      </Container>
    </ContentContainer>
  );
}

const Container = styled.div`
  position: relative;
  height: 430px;
  color: ${colors.primary};
  width: 100%;
  margin-top: 25px;
`;

const anim = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const MainStyledPlainPicture = styled(PlainPicture)`
  position: absolute !important;
  z-index: -1;
  height: 100%;
`;

const Inner = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  border-radius: 10px;
  ${MainStyledPlainPicture} {
    img {
      width: 100vw;
      min-width: 1260px;
      height: 100%;
      object-fit: cover;
    }
  }
  .swiper-container {
    height: 100%;
  }
  .swiper-pagination {
    display: flex;
    justify-content: center;
    height: 20px;
    bottom: 20px;
  }
`;

const SliderWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 75px;
`;

const Title = styled.span`
  font-size: 48px;
  font-weight: 600;
  font-family: ${fonts.AlternatesMontserrat};
`;

const Description = styled.p`
  font-size: 18px;
  line-height: 25px;
  font-style: italic;
  text-align: center;
  font-weight: 500;
  margin: 10px 0 0;
  max-width: 300px;
`;

const StyledButton = styled(Button)`
  width: 290px;
  font-size: 16px;
  border-radius: 3px;
  margin-top: 15px;
  &:hover {
    background: #198738;
    border-color: #198738;
  }
`;

const ItemsPagination = styled.div`
  position: relative;

  .swiper-pagination-bullet {
    position: relative;
    display: block;
    max-width: 30px;
    flex: 1 1 auto;
    height: 5px;
    border-radius: 10% / 50%;
    background-color: transparent;
    border: 1px solid ${colors.white};
    opacity: 1;
    margin-left: 10px;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }

    &:before {
      position: absolute;
      left: 0;
      width: 0;
      content: '';
      height: 100%;
      background-color: ${colors.white};
      opacity: 1;
    }
  }
  .swiper-pagination-bullet-active {
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      will-change: transform;
      animation: ${anim} 4350ms linear;
    }
  }
}
`;

export default SamovarkinHomeBanners;
