import React from 'react';
import styled from 'styled-components';

import RassrochkaTitleSection from './components/RassrochkaTitleSection';
import RassrochkaOptionsSection from './components/RassrochkaOptionsSection';
import RassrochkaCardsSection from './components/RassrochkaCardsSection';
import RassrochkaCalculatorSection from './components/RassrochkaСalculatorSection';

function Rassrochka() {
  return (
    <Container>
      <RassrochkaTitleSection />
      <Wrapper>
        <RassrochkaCalculatorSection />
      </Wrapper>
      <Wrapper>
        <RassrochkaOptionsSection />
      </Wrapper>
      <Wrapper>
        <RassrochkaCardsSection />
      </Wrapper>
    </Container>
  );
}

const Container = styled.div``;

const Wrapper = styled.div`
  margin-bottom: 50px;
`;

export default Rassrochka;
