import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { useGallery } from '@tager/web-components';

import DocumentCard from '@/modules/Payment/components/DocumentCard';
import { PaymentPageType } from '@/typings/model';
import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import TextHtml from '@/components/TextHTML';

import { TitleWrapper, Title } from '../Payment.style';

function DocumentCardsSection() {
  const page = useCurrentPage<PaymentPageType>();
  const pageFields = page?.templateFields;

  const openGallery = useGallery();

  const imageList = useMemo(
    () =>
      pageFields?.documentsItems?.map((document) => ({
        url: document.file?.original?.url ?? '',
        caption: document.caption,
      })) ?? [],
    [pageFields]
  );

  return (
    <ContentContainer>
      <TitleWrapper>
        <Title>{pageFields?.documentsTitle}</Title>
      </TitleWrapper>
      <Inner>
        <Docs>
          {pageFields?.documentsItems?.map((document, index) => (
            <DocumentWrapper key={index}>
              <DocumentCard
                {...document}
                onClick={() => openGallery({ imageList, initialIndex: index })}
              />
            </DocumentWrapper>
          ))}
        </Docs>
        {pageFields?.documentsText ? (
          <TextWrapper>
            <TextHtml text={pageFields.documentsText} />
          </TextWrapper>
        ) : null}
      </Inner>
    </ContentContainer>
  );
}

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.laptop(
    css`
      display: block;
    `
  )}
`;

const Docs = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const DocumentWrapper = styled.div`
  width: 32%;
`;

const TextWrapper = styled.div`
  flex-basis: 540px;
  margin-left: 35px;
  ${media.laptop(
    css`
      margin-left: 0;
      margin-top: 30px;
    `
  )}
`;

export default DocumentCardsSection;
