import React from 'react';
import styled from 'styled-components';

import { getCurrentProject } from '@/utils/common';

import HomeSeo from '../components/HomeSeo';

import DefaultHomeBanners from './components/DefaultHomeBanners';
import DefaultHomeCatalog from './components/DefaultHomeCatalog';
import BelmebelHomePrices from './components/BelmebelHomePrices';
import DefaultHomeProducts from './components/DefaultHomeProducts';
import DefaultHomePosts from './components/DefaultHomePosts';

function Home() {
  const project = getCurrentProject();
  return (
    <Container>
      <Wrapper>
        <DefaultHomeBanners />
      </Wrapper>
      <Wrapper>
        <DefaultHomeCatalog />
      </Wrapper>
      {project === 'BELMEBEL' && 0 ? (
        <Wrapper>
          <BelmebelHomePrices />
        </Wrapper>
      ) : null}
      <DefaultHomeProducts />
      <Wrapper>
        <DefaultHomePosts />
      </Wrapper>
      <Wrapper>
        <HomeSeo />
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 40px;
  overflow: hidden;
`;

const Wrapper = styled.div`
  margin: 40px -5px 0;
`;

export default Home;
