import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { PlainPicture } from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { PaymentCardType } from '@/typings/model';

type Props = PaymentCardType & {
  className?: string;
};

function getLink(url: string | null | undefined): string | null {
  if (!url) return null;
  let urlFiltered = url.substring(url.indexOf('//') + 2);

  if (urlFiltered.indexOf('/') === -1) {
    return urlFiltered;
  }

  return urlFiltered.substring(0, urlFiltered.indexOf('/')).replace('www.', '');
}

function PaymentCard({
  bankName,
  bankUrl,
  image,
  name,
  period,
  note,
  noteHighlight,
  disabledText,
  className,
}: Props) {
  const cardLink = getLink(bankUrl);
  return (
    <Cell className={className}>
      <Container>
        <Bank>
          <BankName>{bankName}</BankName>
          <BankLink href={bankUrl || undefined}>{cardLink}</BankLink>
        </Bank>
        <Inner>
          <PictureWrapper>
            <PlainPicture
              {...convertThumbnailToPictureProps(image)}
              alt={name ?? 'plastic card'}
            />
          </PictureWrapper>
          <Content>
            <Title>{name}</Title>
            <Text>{period}</Text>
          </Content>
        </Inner>
        {note && (
          <Bottom>{noteHighlight ? <Strong>{note}</Strong> : note}</Bottom>
        )}
        {disabledText ? (
          <DisabledWrapper>
            <DisabledText>{disabledText}</DisabledText>
          </DisabledWrapper>
        ) : null}
      </Container>
    </Cell>
  );
}

const Cell = styled.div``;

const Container = styled.div`
  height: 100%;
  overflow: hidden;
  border: 1px solid ${colors.gray100};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

const Bank = styled.div`
  right: 20px;
  top: 20px;
  text-align: right;
  position: absolute;
  ${media.mobile(css`
    position: relative;
  `)}
`;

const BankName = styled.span`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
`;

const BankLink = styled.a`
  color: ${colors.primary};
  &:hover {
    color: ${colors.primary100};
    text-decoration: underline;
  }
`;

const Inner = styled.div`
  padding: 25px;
  display: flex;
  align-items: center;
  ${media.mobile(css`
    flex-direction: column;
    justify-content: center;
  `)}
`;

const Content = styled.div`
  flex: 1;
  ${media.mobile(css`
    text-align: center;
  `)}
`;

const Title = styled.span`
  font-size: 20px;
  display: block;
  font-weight: 500;
  ${media.mobile(css`
    margin-bottom: 5px;
    margin-top: 10px;
  `)}
`;

const Text = styled.span`
  font-size: 20px;
  display: block;
  font-weight: 500;
  margin-top: 15px;
`;

const PictureWrapper = styled.div`
  margin-right: 25px;
  img {
    height: 142px;
    width: 225px;
    border-radius: 10px;
  }
  ${media.mobile(css`
    margin-right: 0;
  `)}
`;

const Bottom = styled.div`
  padding: 0 20px;
  background: ${colors.gray100};
  font-size: 13px;
  line-height: 24px;
  color: ${colors.black};
`;

const Strong = styled.div`
  color: ${colors.primary};
  font-weight: 500;
`;

const DisabledWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const DisabledText = styled.div`
  font-size: 35px;
  padding: 20px 40px;
  background: ${colors.gray100};
  text-align: center;
`;

export default PaymentCard;
