import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';
import { createMediaQuery } from '@tager/web-components';

import ContentContainer from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import CatalogCard from '@/components/CatalogCard';
import { DefaultHomePageType } from '@/typings/model';
import { media } from '@/utils/mixin';
import { breakpoints } from '@/constants/theme';

import { Title } from '../DefaultHome.style';

function DefaultHomeCatalog() {
  const page = useCurrentPage<DefaultHomePageType>();
  const templateFields = page?.templateFields;

  const isLaptop = useMedia(createMediaQuery({ max: breakpoints.desktop }));

  const portraitItem = templateFields?.catalogItems?.find(
    (item) => item.mode === 'portrait'
  );

  const landscapeItemList = templateFields?.catalogItems?.filter(
    (portraitItem) => portraitItem.mode === 'landscape'
  );

  const squareItemList = templateFields?.catalogItems?.filter(
    (squareItem) => squareItem.mode === 'square'
  );

  return (
    <ContentContainer>
      <Title>{templateFields?.catalogTitle}</Title>
      <Row>
        <PortraitWrapper>
          {portraitItem ? <PortraitCatalogCard {...portraitItem} /> : null}
        </PortraitWrapper>
        <SquareAndLandscapeWrapper>
          {portraitItem && isLaptop ? (
            <LandscapeCatalogCard {...portraitItem} mode="landscape" />
          ) : null}
          {landscapeItemList?.map((catalogItem, index: number) => (
            <LandscapeCatalogCard key={index} {...catalogItem} />
          ))}
          {squareItemList?.map((catalogItem, index: number) => (
            <SquareCatalogCard key={index} {...catalogItem} />
          ))}
        </SquareAndLandscapeWrapper>
      </Row>
    </ContentContainer>
  );
}
const Row = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  margin-top: 40px;
`;

const PortraitWrapper = styled.div`
  flex: 0 0 25%;
  display: flex;
  ${media.laptop(
    css`
      display: none;
    `
  )}
`;

const SquareAndLandscapeWrapper = styled.div`
  display: flex;
  flex: 0 0 75%;
  flex-wrap: wrap;
  margin: -10px;
  ${media.laptop(
    css`
      flex: 1;
    `
  )};
`;

const PortraitCatalogCard = styled(CatalogCard)`
  flex: 1 0 calc(100% - 20px);
`;

const LandscapeCatalogCard = styled(CatalogCard)`
  flex: 1 0 calc(50% - 20px);
  margin: 10px;
  ${media.laptop(
    css`
      flex: 1 0 calc(33.3333% - 20px);
    `
  )}
`;

const SquareCatalogCard = styled(CatalogCard)`
  flex-basis: calc(25% - 20px);
  margin: 10px;
`;

export default DefaultHomeCatalog;
