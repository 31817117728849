import React from 'react';
import styled from 'styled-components';

import { Nullable } from '@tager/web-core';

import { colors } from '@/constants/theme';

type Props = {
  text: string;
  className?: string;
  itemProp?: Nullable<string>;
};

function TextHtml({ text, className, itemProp = null }: Props) {
  return (
    <Text
      itemprop={itemProp}
      className={className}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
}

const Text = styled.div<{ itemprop: Nullable<string> }>`
  font-size: 14px;
  line-height: 24px;

  *:first-child {
    margin-top: 0 !important;
  }

  p {
    margin: 15px 0;
  }

  h2 {
    font-size: 24px;
    margin: 30px 0 15px;
    line-height: 40px;
  }

  h3 {
    font-size: 22px;
    margin: 30px 0 15px;
    line-height: 36px;
    font-weight: 500;
  }

  h4 {
    font-size: 20px;
    margin: 25px 0 15px;
    line-height: 32px;
  }

  h5 {
    font-size: 18px;
    margin: 25px 0 15px;
    line-height: 28px;
  }

  h6 {
    font-size: 16px;
    margin: 20px 0 15px;
    line-height: 26px;
  }

  a {
    color: ${colors.primary};
    font-weight: 500;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  li {
    list-style-type: disc;
    margin: 10px 0 10px 30px;
  }

  ul,
  ol {
    margin: 20px 0;
  }

  figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin: 25px 0;
  }

  figcaption {
    margin-top: 10px;
    font-weight: 500;
  }
`;

export default TextHtml;
