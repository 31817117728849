import React from 'react';
import { useSelector } from 'react-redux';

import { DefaultHomePageType } from '@/typings/model';
import useCurrentPage from '@/hooks/useCurrentPage';
import { selectBlogPostListResource } from '@/store/reducers/tager/blog';
import PostsSection from '@/components/postsSection';

function DefaultHomePosts() {
  const posts = useSelector(selectBlogPostListResource);
  const page = useCurrentPage<DefaultHomePageType>();
  const templateFields = page?.templateFields;

  return !!posts.data.length ? (
    <PostsSection posts={posts} title={templateFields?.blogTitle} />
  ) : null;
}

export default DefaultHomePosts;
