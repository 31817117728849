import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import ContentContainer from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import { PaymentPageType } from '@/typings/model';
import useCurrentPage from '@/hooks/useCurrentPage';

import PaymentMethodCard from './PaymentMethodCard';

function PaymentMethodSection() {
  const page = useCurrentPage<PaymentPageType>();
  const pageFields = page?.templateFields;

  return (
    <Container>
      <ContentContainer>
        <Inner>
          {pageFields?.items?.map((paymentMethodCard, index) => (
            <StyledPaymentMethodCard key={index} {...paymentMethodCard} />
          ))}
        </Inner>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  background: ${colors.gray};
`;

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  padding: 20px 0;
`;

const StyledPaymentMethodCard = styled(PaymentMethodCard)`
  padding: 15px;
  width: 33.33%;
  ${media.laptop(
    css`
      width: 50%;
    `
  )}
`;

export default PaymentMethodSection;
