import React from 'react';

import { getCurrentProject } from '@/utils/common';

import { DefaultHome, BelsexHome, SamovarkinHome } from './HomeProject';

function Home() {
  const project = getCurrentProject();

  switch (project) {
    case 'BELSEX':
      return <BelsexHome />;
    case 'SAMOVARKIN':
      return <SamovarkinHome />;
    default:
      return <DefaultHome />;
  }
}

export default Home;
