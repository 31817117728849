import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { CreditPriceSettingItem } from '@/typings/model';

type Props = {
  options: Array<CreditPriceSettingItem>;
  emptyDataText?: string;
};

function CreditOptionsTable({ options, emptyDataText }: Props) {
  return (
    <Container>
      <Header>Срок рассрочки</Header>
      <StyledHeader>Первоначальный взнос</StyledHeader>
      <Header>Переплата</Header>

      {options.length > 0 ? (
        options.map((value, index) => (
          <Row key={index}>
            <Cell>{value.period} мес.</Cell>
            <Cell>{value.initial} %</Cell>
            <Cell>{value.underPayment} %</Cell>
          </Row>
        ))
      ) : (
        <Row>
          <Text>{emptyDataText}</Text>
        </Row>
      )}
    </Container>
  );
}

const Container = styled.div`
  border: 2px solid ${colors.primary};
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 20px 30px 30px;
  position: relative;
  min-height: 250px;
  ${media.mobile(
    css`
      padding: 10px;
    `
  )}
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Cell = styled.div`
  font-size: 22px;
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
  ${media.mobile(
    css`
      font-size: 15px;
    `
  )}
`;

const Header = styled(Cell)`
  color: ${colors.primary};
  font-weight: 600;
  font-size: 21px;
  text-align: center;
  padding: 0.8em 0;
  width: 33.33%;
  height: min-content;

  ${media.mobile(
    css`
      width: 30%;
      font-size: 13px;
    `
  )}
`;

const StyledHeader = styled(Header)`
  ${media.mobile(
    css`
      width: 40%;
    `
  )}
`;

const Text = styled.div`
  display: block;
  font-size: 35px;
  font-weight: 500;
  ${media.mobile(
    css`
      font-size: 25px;
    `
  )}
`;

export default CreditOptionsTable;
