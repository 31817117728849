import React from 'react';
import styled from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { FilledConsultant } from '@/components/Consultant';

function FaqConsultantSection() {
  return (
    <ContentContainer>
      <Inner>
        <FilledConsultant />
      </Inner>
    </ContentContainer>
  );
}

const Inner = styled.div`
  max-width: 1100px;
`;

export default FaqConsultantSection;
