import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { FETCH_STATUSES, generateNumberArray } from '@tager/web-core';

import useCurrentPage from '@/hooks/useCurrentPage';
import { DefaultHomePageType, ProductsBlockType } from '@/typings/model';
import { useTypedSelector } from '@/store/store';
import {
  getProductListByGroupThunk,
  selectProductListByGroupResource,
} from '@/store/reducers/pages/products';
import ContentContainer from '@/components/ContentContainer';
import PlaceholderCard from '@/components/PlaceholderCard/PlaceholderCard';
import ProductSwiper from '@/components/ProductSwiper';

import {
  Title,
  ProductPlaceholderWrapper,
  ProductWrapper,
  ContentWrapper,
} from '../DefaultHome.style';

type Props = {
  product: ProductsBlockType;
  index: number;
};

function ProductListByGroup({ product, index }: Props) {
  const productByGroupResource = useTypedSelector((state) =>
    selectProductListByGroupResource(state, product.productGroup)
  );

  if (
    productByGroupResource?.status === FETCH_STATUSES.FAILURE ||
    !productByGroupResource?.data.length
  )
    return null;

  return (
    <ContentContainer>
      <ContentWrapper>
        <Title>{product.title}</Title>
        {productByGroupResource.status === FETCH_STATUSES.LOADING ? (
          <ProductPlaceholderWrapper>
            {generateNumberArray(6).map((num) => (
              <ProductWrapper key={num}>
                <PlaceholderCard height={360} />
              </ProductWrapper>
            ))}
          </ProductPlaceholderWrapper>
        ) : productByGroupResource.status === FETCH_STATUSES.SUCCESS ? (
          <ProductSwiper products={productByGroupResource.data} index={index} />
        ) : null}
      </ContentWrapper>
    </ContentContainer>
  );
}

function DefaultHomeProducts() {
  const page = useCurrentPage<DefaultHomePageType>();
  const templateFields = page?.templateFields;
  const dispatch = useDispatch();

  useEffect(() => {
    templateFields?.productBlocks?.map((product) =>
      dispatch(getProductListByGroupThunk(product.productGroup))
    );
  }, [templateFields, dispatch]);

  return (
    <>
      {templateFields?.productBlocks?.map((product, index) => (
        <ProductListByGroup product={product} index={index} key={index} />
      ))}
    </>
  );
}

export default DefaultHomeProducts;
