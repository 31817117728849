import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { DeliveryPageType } from '@/typings/model';
import useCurrentPage from '@/hooks/useCurrentPage';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import DeliveryCard from '@/components/DeliveryCard';
import { PlainPicture } from '@/components/Picture';
import { media } from '@/utils/mixin';

function DeliveryPricesCardSection() {
  const page = useCurrentPage<DeliveryPageType>();
  const pageFields = page?.templateFields;

  return (
    <Container>
      <ContentContainer>
        <Row>
          <CellWithText>
            <Inner>
              {pageFields?.items?.map((pricesCard, index) => (
                <StyledDeliveryCard key={index} {...pricesCard} />
              ))}
            </Inner>
          </CellWithText>
          <CellWithPicture>
            <PlainPicture
              {...convertThumbnailToPictureProps(pageFields?.image)}
            />
          </CellWithPicture>
        </Row>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  background: ${colors.gray};
`;

const Row = styled.div`
  display: flex;
`;

const CellWithText = styled.div`
  flex: 2.2;
  margin-right: 30px;
`;

const CellWithPicture = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDeliveryCard = styled(DeliveryCard)`
  margin-bottom: 30px;

  &:first-child {
    margin-right: 30px;
  }

  &:nth-child(2) {
    flex: 1;
  }

  &:last-child {
    flex: 1;
    margin-bottom: 0;
  }

  ${media.laptop(
    css`
      align-self: initial;
      &:first-child {
        margin-right: 0;
      }
    `
  )};
`;

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0;
  ${media.laptop(
    css`
      flex-flow: column wrap;
    `
  )}
`;

export default DeliveryPricesCardSection;
