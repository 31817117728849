import React, { useState, useEffect, ChangeEvent, FocusEvent } from 'react';
import styled from 'styled-components';

import { Nullable } from '@tager/web-core';

import ContentContainer from '@/components/ContentContainer';
import InstallmentTermsBlock from '@/components/InstallmentTermsBlock';
import TextInput from '@/components/Input/TextInput';
import { colors } from '@/constants/theme';
import Select from '@/components/Select';
import useSettingItem from '@/hooks/useSettingItem';
import { calculatePayment } from '@/utils/calculatePayment';
import { integerParser } from '@/utils/common';

import {
  getCreditTermOptions,
  getMinimalInitialPayment,
} from '../Rassrochka.helpers';
import { PeriodOptionType } from '../Rassrochka.types';

const INITIAL_PRICE = 1000;

function RassrochkaCalculatorSection() {
  const creditPrices = useSettingItem('PRICES_CREDIT');
  const creditTerms = getCreditTermOptions(creditPrices);

  const [priceValue, setPriceValue] = useState<number>(1000);
  const [period, setPeriod] = useState<Nullable<PeriodOptionType>>(
    creditTerms[0]
  );

  const minimalInitialPayment = getMinimalInitialPayment(period, priceValue);

  const [initialPayment, setInitialPayment] = useState(minimalInitialPayment);

  const payment = calculatePayment({
    price: priceValue,
    period: period?.value || 0,
    initial: initialPayment,
    underPayment: period?.underPayment,
  });

  useEffect(() => {
    setInitialPayment(minimalInitialPayment);
  }, [priceValue, period]);

  const onPriceChange = (event: ChangeEvent<HTMLInputElement>) =>
    setPriceValue(
      Number.parseInt(
        integerParser(event.target.value || '0', String(priceValue))
      )
    );
  const onPriceBlur = (event: FocusEvent<HTMLInputElement>) =>
    event.target.value === '' ? setPriceValue(INITIAL_PRICE) : null;

  const onInitialPaymentChange = (event: ChangeEvent<HTMLInputElement>) =>
    setInitialPayment(
      Number.parseInt(
        integerParser(event.target.value || '0', String(initialPayment))
      )
    );
  const onInitialPaymentBlur = (event: FocusEvent<HTMLInputElement>) =>
    event.target.value === '' ? setInitialPayment(minimalInitialPayment) : null;

  return (
    <Container>
      <ContentContainer>
        <Wrapper>
          <Calculator>
            <Cell>
              <Title>Стоимость товара, руб.</Title>
              <TextInput
                icon="cart"
                value={priceValue}
                onBlur={onPriceBlur}
                onChange={onPriceChange}
              />
            </Cell>
            <Cell>
              <Title>Сроки рассрочки</Title>
              <Select<PeriodOptionType>
                options={creditTerms}
                value={period}
                onChange={setPeriod}
              />
            </Cell>
            <Cell>
              <Title>Первоначальный взнос, руб.</Title>
              <TextInput
                icon="cart"
                value={initialPayment}
                onBlur={onInitialPaymentBlur}
                onChange={onInitialPaymentChange}
              />
            </Cell>
          </Calculator>
          <InstallmentTermsBlock
            total={payment.total}
            add={payment.additional}
            monthPayment={payment.month}
          />
        </Wrapper>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  background: ${colors.gray};
  padding: 35px 0;
`;

const Wrapper = styled.div`
  width: 910px;
`;

const Calculator = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
`;

const Title = styled.span`
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
`;

export default RassrochkaCalculatorSection;
