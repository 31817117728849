import React from 'react';
import styled from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import { SamovarkinContactsPageType } from '@/typings/model';
import { fonts } from '@/constants/theme';

import SamovarkinContactsForm from './components/SamovarkinContactsForm';
import SamovarkinContactsInfo from './components/SamovarkinContactsInfo';

function SamovarkinContacts() {
  const page = useCurrentPage<SamovarkinContactsPageType>();
  const templateFields = page?.templateFields;

  const contactsBlock = templateFields?.blocks;
  const formTitle = templateFields?.formTitle;
  const pageTitle = page?.title;

  return (
    <Container>
      <Wrapper>
        <InnerWrapper>
          <PageTitle>{pageTitle}</PageTitle>
          <SamovarkinContactsInfo contactsBlock={contactsBlock} />
        </InnerWrapper>
        <InnerWrapper>
          <SamovarkinContactsForm title={formTitle} />
        </InnerWrapper>
      </Wrapper>
    </Container>
  );
}

export default SamovarkinContacts;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
  margin-bottom: 70px;
`;

const Container = styled.div`
  max-width: calc(100% - 30px);
  width: 1710px;
  margin: 0 auto;
  padding: 0 15px;
`;

const InnerWrapper = styled.div`
  flex: 1;
`;

const PageTitle = styled.h1`
  font-family: ${fonts.AlternatesMontserrat};
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: 600;
  line-height: 37px;
  color: var(--main-color);
`;
