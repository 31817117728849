import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';

import { media } from '@/utils/mixin';
import { DefaultHomePageType } from '@/typings/model';
import { ReactComponent as SlideArrowIcon } from '@/assets/svg/slide-arrow.svg';
import ContentContainer from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import SidebarBanner from '@/components/SidebarBanner';
import MainBanner from '@/components/MainBanner';
import PlaceholderCard from '@/components/PlaceholderCard';

SwiperCore.use([Navigation, Autoplay]);

function DefaultHomeBanners() {
  const page = useCurrentPage<DefaultHomePageType>();
  const templateFields = page?.templateFields;
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Container>
      <ContentContainer>
        <Inner>
          <Main>
            {isMounted ? (
              <Swiper
                slidesPerView={1}
                loop
                speed={500}
                autoplay={{ delay: 8000 }}
                navigation={{
                  nextEl: '.swiper-next',
                  prevEl: '.swiper-prev',
                }}
              >
                <ButtonWrapper>
                  <NavButton next className="swiper-next">
                    <SlideArrowIcon />
                  </NavButton>
                </ButtonWrapper>
                {templateFields?.bannersMain?.map((bannerMain, index) => (
                  <SwiperSlide key={index}>
                    <MainBanner {...bannerMain} />
                  </SwiperSlide>
                ))}
                <ButtonWrapper left>
                  <NavButton className="swiper-prev">
                    <SlideArrowIcon />
                  </NavButton>
                </ButtonWrapper>
              </Swiper>
            ) : (
              <PlaceholderCard height={450} color="#f0f0f0" />
            )}
          </Main>
          <Sidebar>
            {templateFields?.bannersSidebar?.map((bannerSidebar, index) => (
              <StyledSidebarBanner key={index} {...bannerSidebar} />
            ))}
          </Sidebar>
        </Inner>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  overflow: hidden;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  height: 450px;
  ${media.laptop(
    css`
      display: block;
      height: auto;
    `
  )};
`;

const Main = styled.div`
  flex: 1;
  overflow: hidden;
  ${media.laptop(
    css`
      margin-bottom: 20px;
      height: 450px;
    `
  )}
`;

const Sidebar = styled.div`
  flex-basis: 550px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 20px;

  ${media.laptop(
    css`
      flex-basis: 100%;
      flex-direction: row;
      margin-left: 0;
    `
  )};
`;

const StyledSidebarBanner = styled(SidebarBanner)`
  height: 215px;
  ${media.laptop(
    css`
      flex-basis: calc(50% - 10px);
    `
  )}
`;

const ButtonWrapper = styled.div<{ left?: boolean }>`
  position: absolute;
  top: 0;
  right: ${(props) => (props.left ? 'auto' : '0')};
  left: auto;
  bottom: 0;
  width: 60px;
  z-index: 10;
`;

const NavButton = styled.button<{ next?: boolean }>`
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  svg {
    transform: ${(props) => (props.next ? 'rotate(180deg)' : 'rotate(0deg)')};
    width: 27px;
    height: 51px;
  }
`;

export default DefaultHomeBanners;
