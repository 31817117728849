import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { CatalogSamovarkinType } from '@/typings/model';
import { CatalogCardVariant } from '@/typings/common';
import { colors, fonts } from '@/constants/theme';
import Link from '@/components/Link';
import { PlainPicture } from '@/components/Picture';
import Placeholder from '@/assets/images/placeholder.png';

type Props = CatalogSamovarkinType & {
  className?: string;
  mode: CatalogCardVariant;
};

function SamovarkinHomeCatalogCard({ className, mode, ...card }: Props) {
  const { title, image, link, children } = card;

  return (
    <Inner hasLink={!!link} mode={mode} className={className}>
      {image ? (
        <PictureWrapper>
          <CardPicture
            {...convertThumbnailToPictureProps(image)}
            alt={title ?? ''}
          />
        </PictureWrapper>
      ) : (
        <PictureWrapper>
          <CardPicture src={Placeholder} />
        </PictureWrapper>
      )}
      <InfoWrapper>
        <Title>{title}</Title>
        {children && !!children.length ? (
          <ChildrenWrapper>
            {children.map((item) => (
              <Link to={item.link}>
                <ChildrenTitle>{item.title}</ChildrenTitle>
              </Link>
            ))}
          </ChildrenWrapper>
        ) : null}
      </InfoWrapper>
    </Inner>
  );
}

const Title = styled.h2`
  position: relative;
  font-weight: 600;
  max-width: 250px;
  color: ${colors.primary};
  font-family: ${fonts.AlternatesMontserrat};
`;

const PictureWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const CardPicture = styled(PlainPicture)`
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: all 0.45s ease-in-out 0s;
    object-position: top;
`;

const Inner = styled.div<{ hasLink: boolean; mode: CatalogCardVariant }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 25px 30px;
  height: ${({ mode }) => (mode === 'portrait' ? '444px' : '212px')};
  ${({ hasLink }) =>
    hasLink
      ? css`
          &:hover {
            color: ${colors.primary100};
          }
          &:hover ${CardPicture} {
            img {
              transform: scale(1.1);
            }
          }
        `
      : ''}
  ${Title} {
    font-size: ${({ mode }) => (mode === 'portrait' ? '30' : '22')}px;
    line-height: ${({ mode }) => (mode === 'portrait' ? '36' : '30')}px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChildrenTitle = styled.span`
  display: block;
  font-weight: 500;
  position: relative;
  color: ${colors.primary};
  margin-top: 10px;
  &:hover {
    color: ${colors.primary100};
    text-decoration: underline;
  }
`;

export default SamovarkinHomeCatalogCard;
