import React from 'react';
import styled from 'styled-components';

import Link from '@/components/Link';
import { ReactComponent as ArrowIcon } from '@/assets/svg/breadcrumbs_arrow.svg';

export type BreadcrumbType = {
  label?: string;
  to?: React.ComponentProps<typeof Link>['to'];
};

type Props = {
  breadcrumbs: Array<BreadcrumbType>;
};

function Breadcrumbs({ breadcrumbs }: Props) {
  return (
    <Container>
      <BreadcrumbList itemType="http://schema.org/BreadcrumbList">
        {breadcrumbs.map((item, index) => (
          <BreadcrumbItem
            key={index}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem"
          >
            {breadcrumbs.length === index + 1 ? (
              <span itemProp="name">{item.label}</span>
            ) : (
              <ItemLink to={item.to}>
                <span itemProp="name">{item.label}</span>
              </ItemLink>
            )}
            <IconWrapper>
              <ArrowIcon />
            </IconWrapper>
            <meta itemProp="position" content={(index + 1).toString()} />
          </BreadcrumbItem>
        ))}
      </BreadcrumbList>
    </Container>
  );
}

const Container = styled.div``;
const BreadcrumbList = styled.ul`
  display: flex;
`;
const BreadcrumbItem = styled.li`
  position: relative;
  margin-left: 10px;
  padding-right: 17px;
  font-size: 12px;
  line-height: 16px;
  color: #7e7e7e;
  font-weight: 500;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    padding-right: 0;
    svg {
      display: none;
    }
  }
`;
const ItemLink = styled(Link)`
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #aaa;
  }
`;

const IconWrapper = styled.div`
  display: block;
  position: absolute;
  right: 0;
  top: 2px;
  opacity: 0.75;
`;

export default Breadcrumbs;
