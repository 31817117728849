import React, { useState } from 'react';
import styled from 'styled-components';

import { nl2br, Nullish } from '@tager/web-core';

import { SamovarkinContactsBlockTypes } from '@/typings/model';
import { colors, fonts } from '@/constants/theme';
import { HeaderCallForm as CallForm } from '@/components/form/CallForm';
import { ReactComponent as PhoneIcon } from '@/assets/svg/phone-1.svg';

interface PropsType {
  contactsBlock: Nullish<Array<SamovarkinContactsBlockTypes>>;
}

function SamovarkinContactsInfo({ contactsBlock }: PropsType) {
  const [isCallFormOpened, setCallFormOpened] = useState(false);

  function onClickToCallButton() {
    setCallFormOpened(!isCallFormOpened);
  }

  function onClose() {
    setCallFormOpened(false);
  }

  return (
    <Wrapper>
      <ItemList>
        {contactsBlock?.map((block, index) => {
          const isRequisites = index === contactsBlock.length - 1;

          return (
            <WrapperItem>
              <Title>{block.title}</Title>
              {isRequisites ? (
                <ItemList>
                  <Text lineHeight={'28px'} fontSize={index}>
                    {nl2br(block.body)}
                  </Text>
                </ItemList>
              ) : (
                <Text lineHeight={'24px'} fontSize={index}>
                  {block.body}
                </Text>
              )}
              {block.buttonLabel ? (
                <WrapperButton display={'inline-block'}>
                  <CallButton onClick={onClickToCallButton}>
                    <PhoneIcon />
                    {block.buttonLabel}
                  </CallButton>
                  {isCallFormOpened ? <CallForm onClose={onClose} /> : null}
                </WrapperButton>
              ) : null}
            </WrapperItem>
          );
        })}
      </ItemList>
    </Wrapper>
  );
}

export default SamovarkinContactsInfo;

function getFontSize(index: number): string {
  return index < 2 ? '18px' : '15px';
}

const Wrapper = styled.div``;

const ItemList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  display: block;
  font-family: ${fonts.Montserrat};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
  padding-bottom: 9px;
`;

const Text = styled.span<{ lineHeight: string; fontSize: number }>`
  font-family: ${fonts.Montserrat};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ lineHeight }) => lineHeight};
  font-size: ${({ fontSize }) => getFontSize(fontSize)};
`;

const WrapperItem = styled.div`
  margin-bottom: 40px;
`;

const CallButton = styled.button`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 38px;
  padding: 0 12px 0 0;
  border: 1px solid;
  border-radius: 3px;
  border-color: var(--main-color);
  background: transparent;
  color: var(--main-color);

  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;

  transition: all 0.3s;

  svg {
    fill: var(--main-color);
    margin-right: 13px;
    margin-left: 16px;
  }

  &:hover > svg {
    fill: ${colors.white};
  }

  &:hover {
    background: var(--main-color);
    color: ${colors.white};
  }
`;

const WrapperButton = styled.div<{ display: string | null }>`
  display: ${({ display }) => display ?? 'block'};
  margin-left: 15px;
  position: relative;
`;
