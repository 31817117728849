import { isNotNullish, Nullable } from '@tager/web-core';

import { CreditPriceSettingItem } from '@/typings/model';

import { PeriodOptionType } from './Rassrochka.types';

function isNonNullCreditPriceItem(
  option: CreditPriceSettingItem
): option is {
  [key in keyof CreditPriceSettingItem]: string;
} {
  return (
    isNotNullish(option.period) &&
    isNotNullish(option.initial) &&
    isNotNullish(option.underPayment)
  );
}

export function getCreditTermOptions(
  credit: Array<CreditPriceSettingItem>
): Array<PeriodOptionType> {
  return credit.filter(isNonNullCreditPriceItem).map((setting) => ({
    label: `${setting.period} мес.`,
    value: Number.parseInt(setting.period),
    initial: Number.parseInt(setting.initial),
    underPayment: Number.parseInt(setting.underPayment),
  }));
}

export function getMinimalInitialPayment(
  currentPeriod: Nullable<PeriodOptionType>,
  price: number
): number {
  if (!currentPeriod) return 0;
  return Number.parseInt(((currentPeriod.initial / 100) * price).toFixed(2));
}
