import { Nullish } from '@tager/web-core';

import { PageModuleType } from '@/typings/common';
import DefaultTemplate from '@/modules/DefaultTemplate';
import Home from '@/modules/Home';
import Payment from '@/modules/Payment';
import Delivery from '@/modules/Delivery';
import Contacts from '@/modules/Contacts';
import Rassrochka from '@/modules/Rassrochka';
import Faq from '@/modules/Faq';
import { getBlogPostListThunk } from '@/store/reducers/tager/blog';

const DEFAULT_PAGE_MODULE: PageModuleType = {
  component: DefaultTemplate,
  template: '',
};

const PAGE_MODULE_LIST: Array<PageModuleType> = [
  {
    template: 'belmebel:home',
    component: Home,
    getInitialProps({ store }) {
      return Promise.all([store.dispatch(getBlogPostListThunk())]);
    },
  },
  {
    template: 'belsex:home',
    component: Home,
  },
  {
    template: 'samovarkin:home',
    component: Home,
  },
  {
    template: 'belmebel:payment',
    component: Payment,
  },
  {
    template: 'belmebel:delivery',
    component: Delivery,
  },
  {
    template: 'samovarkin:contacts',
    component: Contacts,
  },
  {
    template: 'belmebel:contacts',
    component: Contacts,
  },
  {
    template: 'belmebel:rassrochka',
    component: Rassrochka,
  },
  {
    template: 'belmebel:faq',
    component: Faq,
  },
];

export function getPageModuleByTemplate(
  template: Nullish<string>
): PageModuleType {
  return (
    PAGE_MODULE_LIST.find((pageModule) => pageModule.template === template) ??
    DEFAULT_PAGE_MODULE
  );
}
