import React from 'react';
import styled from 'styled-components';

import { Nullable } from '@tager/web-core';
import { SvgComponent } from '@tager/web-components';

import { colors } from '@/constants/theme';
import { ReactComponent as FactoryIcon } from '@/assets/svg/factory.svg';
import { ReactComponent as PriceIcon } from '@/assets/svg/price.svg';
import { ReactComponent as ShopIcon } from '@/assets/svg/shop.svg';
import { ReactComponent as WarehouseIcon } from '@/assets/svg/warehouse.svg';
import { ReactComponent as PeopleIcon } from '@/assets/svg/people.svg';
import { ReactComponent as WalletIcon } from '@/assets/svg/wallet.svg';

import { PriceIconType, ColorType } from './PriceCard.types';

export type PriceCardType = {
  icon: PriceIconType;
  text?: string;
  price?: string;
  variant?: ColorType;
  bold?: boolean;
};

type Props = PriceCardType & {
  className?: string;
};

export function getIconComponent(
  iconType: PriceIconType
): Nullable<SvgComponent> {
  switch (iconType) {
    case 'factory':
      return FactoryIcon;
    case 'price':
      return PriceIcon;
    case 'shop':
      return ShopIcon;
    case 'warehouse':
      return WarehouseIcon;
    case 'people':
      return PeopleIcon;
    case 'wallet':
      return WalletIcon;
    default:
      return null;
  }
}

function PriceCard({ className, ...card }: Props) {
  const { icon, variant = 'default', text, price, bold } = card;
  const IconComponent = getIconComponent(icon);
  return (
    <Container variant={variant} bold={bold} className={className}>
      {IconComponent ? (
        <IconWrapper>
          <IconComponent />
        </IconWrapper>
      ) : null}
      {price ? <Price>{price}</Price> : null}
      <Text>{text}</Text>
    </Container>
  );
}

const Container = styled.div<{ variant?: ColorType; bold?: boolean }>`
  width: 200px;
  text-align: center;
  padding-bottom: 30px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    !props.bold
      ? `${colors.gray100}`
      : props.variant === 'default'
      ? `${colors.black}`
      : `${colors.primary}`};
  color: ${(props) =>
    props.variant === 'default' ? `${colors.black}` : `${colors.primary}`};
  svg {
    fill: ${(props) =>
      props.variant === 'default' ? `${colors.black}` : `${colors.primary}`};
  }
`;

const IconWrapper = styled.div`
  padding: 30px 0;
  width: 70px;
  margin: 0 auto;
`;

const Price = styled.div`
  display: block;
  font-size: 24px;
  line-height: 100%;
  font-weight: 600;
  margin-bottom: 5px;
`;

const Text = styled.div`
  display: block;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: ${colors.black};
  padding: 0 15px;
`;

export default PriceCard;
