import React from 'react';
import styled, { css } from 'styled-components';

import { DeliveryPageType } from '@/typings/model';
import useCurrentPage from '@/hooks/useCurrentPage';
import ContentContainer from '@/components/ContentContainer';
import DisabledFeature from '@/components/DisabledFeature';
import TextHtml from '@/components/TextHTML';
import { media } from '@/utils/mixin';

function DeliveryMapAndConditionsSection() {
  const page = useCurrentPage<DeliveryPageType>();
  const pageFields = page?.templateFields;
  return (
    <ContentContainer>
      <Row>
        <Cell>
          <Title>{pageFields?.mapTitle}</Title>
          <DisabledFeature text="Карта в разработке" height={450} />
        </Cell>
        <Cell>
          <Title>{pageFields?.textTitle}</Title>
          <StyledTextHtml text={pageFields?.textContent ?? ''} />
        </Cell>
      </Row>
    </ContentContainer>
  );
}

const Row = styled.div`
  display: flex;
  ${media.laptop(
    css`
      flex-direction: column;
    `
  )}
`;

const Cell = styled.div`
  flex: 1;
  &:first-child {
    margin-right: 20px;
  }
  ${media.laptop(
    css`
      &:first-child {
        margin-right: 0;
        margin-bottom: 40px;
      }
    `
  )}
`;

const Title = styled.h2`
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 25px;
`;

const StyledTextHtml = styled(TextHtml)`
  font-size: 15px;
  line-height: 26px;
  li {
    margin: 23px 0 23px 25px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default DeliveryMapAndConditionsSection;
