import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { PlainPicture } from '@/components/Picture';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import { MainBannersType } from '@/typings/model';

type Props = MainBannersType & {
  className?: string;
};

function MainBanner({ className, ...banner }: Props) {
  const { title, text, image, url, background } = banner;
  return (
    <Link to={url}>
      <Content className={className} background={background}>
        {image ? (
          <PictureWrapper>
            <BannerPicture
              loading="lazy"
              {...convertThumbnailToPictureProps(image)}
              alt={title}
            />
          </PictureWrapper>
        ) : null}
        <Title>{title}</Title>
        <Text>{text}</Text>
      </Content>
    </Link>
  );
}

const Content = styled.div<{ background?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px 60px 0;
  height: 450px;
  background-color: ${(props) => props.background};
  &:hover {
    color: ${colors.primary};
  }
`;

const PictureWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const BannerPicture = styled(PlainPicture)`
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.span`
  display: block;
  position: relative;
  font-size: 30px;
  line-height: 100%;
  font-weight: 500;
  margin-bottom: 20px;
`;

const Text = styled.span`
  display: block;
  position: relative;
  font-size: 22px;
  line-height: 100%;
  font-weight: 500;
  white-space: pre-line;
`;

export default MainBanner;
