import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form as FormikForm, FormikHelpers } from 'formik';

import ComponentButton from '@/components/Button';
import { colors, fonts } from '@/constants/theme';
import { TextInputFormik } from '@/components/Input/TextInput';
import { StringFieldType } from '@/typings/common';
import { FeedbackFormPayload, sendFeedbackForm } from '@/services/requests';
import SuccessForm from '@/components/form/SuccessForm';
import { TextAreaFormik } from '@/components/TextArea';
import { validatePhone } from '@/components/form/form.helpers';
import { ReactComponent as MessageIcon } from '@/assets/svg/chat-2.svg';

interface PropsType {
  title: StringFieldType | undefined;
}

function validate({ phone, message, name }: FeedbackFormPayload) {
  const errors: Partial<FeedbackFormPayload> = {};

  if (!phone) {
    errors.phone = 'Поле обязательно для заполнения';
  } else if (!validatePhone(phone)) {
    errors.phone = 'Неверный Мобильный телефон';
  }
  if (!name.trim()) {
    errors.name = 'Поле обязательно для заполнения';
  }
  if (!message.trim()) {
    errors.message = 'Поле обязательно для заполнения';
  }
  return errors;
}

function SamovarkinContactsForm({ title }: PropsType) {
  const [showSuccessForm, setSuccessForm] = useState(false);

  useEffect(() => {
    return () => {
      setSuccessForm(false);
    };
  }, []);

  function onSubmit(
    values: FeedbackFormPayload,
    formikHelpers: FormikHelpers<FeedbackFormPayload>
  ) {
    return sendFeedbackForm(values)
      .then(() => {
        setSuccessForm(true);
        formikHelpers.setSubmitting(true);
      })
      .catch(() => {
        formikHelpers.setErrors({
          message: 'Произошла ошибка при отправке данных',
        });
        formikHelpers.setStatus({
          hasBackendError: true,
        });
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  }

  return (
    <Wrapper>
      <Title>{title}</Title>
      {showSuccessForm ? (
        <SuccessForm
          title="Сообщение отправлено"
          textNote="Мы благодарим вас за обращение"
          text="В ближайшее время руководство прочитает ваше сообщение, и если будет необходимо, мы свяжемся с вами по указанной почте"
        />
      ) : (
        <Formik<FeedbackFormPayload>
          initialValues={{
            name: '',
            phone: '',
            message: '',
          }}
          onSubmit={onSubmit}
          validate={validate}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <WrapperInput>
                <TextInputFormik
                  name="name"
                  placeholder="Ваше имя *"
                  invalid={false}
                />
              </WrapperInput>
              <WrapperInput>
                <TextInputFormik
                  name="phone"
                  placeholder="Ваш телефон *"
                  invalid={false}
                />
              </WrapperInput>
              <WrapperInput>
                <WrapperIcon>
                  <MessageIcon />
                </WrapperIcon>
                <TextAreaStyled
                  name="message"
                  placeholder="Введите ваш вопрос"
                />
              </WrapperInput>
              <WrapperButton>
                <Button
                  type="submit"
                  variant="contained-primary-w100"
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  Отправить
                </Button>
              </WrapperButton>
            </Form>
          )}
        </Formik>
      )}
    </Wrapper>
  );
}

export default SamovarkinContactsForm;

const Wrapper = styled.div``;

const Form = styled(FormikForm)`
  width: 750px;
  height: 350px;
  padding: 30px 30px 39px 30px;
  background: #ffffff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const Button = styled(ComponentButton)`
  font-family: ${fonts.Montserrat};
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  border-radius: 3px;
`;
const Title = styled.h2`
  font-family: ${fonts.AlternatesMontserrat};
  font-size: 30px;
  font-weight: 600;
  color: var(--main-color);
  margin-top: 0;
  margin-bottom: 30px;
  line-height: 37px;
`;

const WrapperInput = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 25px;
`;

const TextAreaStyled = styled(TextAreaFormik)`
  textarea {
    padding: 10px 46px;
    border: 1px solid ${colors.gray200};
  }
`;

const WrapperButton = styled.div`
  max-width: 290px;
`;

const WrapperIcon = styled.div`
  position: absolute;
  top: 14px;
  left: 15px;
  z-index: 10;
`;
