import React, { useEffect, useRef } from 'react';
import { Swiper } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  EffectFade,
} from 'swiper';

SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);

type Props = {
  children: React.ReactNode;
};

function SwiperBullets({ children }: Props) {
  const swiperRef = useRef<SwiperCore | null>(null);

  function handleVisibilityChange() {
    if (!document.hidden) {
      if (!swiperRef.current) return;
      swiperRef.current?.slideNext();
    }
  }
  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  return (
    <Swiper
      effect="fade"
      fadeEffect={{
        crossFade: true,
      }}
      initialSlide={0}
      slidesPerView={1}
      speed={500}
      autoplay={{ delay: 4000, disableOnInteraction: false }}
      loop={true}
      pagination={{
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      }}
      onInit={(swiperInstance) => {
        swiperRef.current = swiperInstance;
      }}
    >
      {children}
    </Swiper>
  );
}
export default SwiperBullets;
