import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors, fonts } from '@/constants/theme';

export const Title = styled.h2`
  display: block;
  font-family: ${fonts.AlternatesMontserrat};
  font-size: 30px;
  line-height: 37px;
  font-weight: 600;
  color: ${colors.primary};
`;

export const ProductPlaceholderWrapper = styled.div`
  display: flex;
  margin: 0 -5px;
  padding: 20px 0;
`;

export const ProductWrapper = styled.div`
  flex-basis: calc(16.6667% - 10px);
  margin: 0 5px;
  ${media.laptop(
    css`
      flex-basis: calc(20% - 10px);
      &:nth-child(5) {
        display: none;
      }
    `
  )}
  ${media.tablet(
    css`
      flex-basis: calc(25% - 10px);
      &:nth-child(6),
      &:nth-child(5) {
        display: none;
      }
    `
  )}
`;

export const ContentWrapper = styled.div`
  margin-top: 60px;
  height: 337px;
`;
