import React from 'react';
import styled, { css } from 'styled-components';

import { SvgComponent } from '@tager/web-components';
import { Nullable } from '@tager/web-core';

import { ReactComponent as PhoneIcon } from '@/assets/svg/phone.svg';
import { ReactComponent as MailIcon } from '@/assets/svg/mail.svg';
import { media } from '@/utils/mixin';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';

type ContactsIconType = 'phone' | 'mail';

type Props = {
  icon: ContactsIconType;
  children?: React.ReactNode;
};

function getIconComponent(
  icon: Nullable<ContactsIconType>
): Nullable<SvgComponent> {
  switch (icon) {
    case 'phone':
      return PhoneIcon;
    case 'mail':
      return MailIcon;
    default:
      return null;
  }
}

function ContactsBlock({ icon, children }: Props) {
  const IconComponent = getIconComponent(icon);
  return (
    <Content>
      <IconWrapper>{IconComponent ? <IconComponent /> : null}</IconWrapper>
      <Inner variant={icon}>{children}</Inner>
    </Content>
  );
}

const Content = styled.div`
  display: flex;
  align-items: center;
  ${media.mobileMedium(css`
    flex-direction: column;
    align-items: center;
  `)}
`;

const IconWrapper = styled.div`
  margin-right: 25px;
  svg {
    width: 75px;
    height: 75px;
    fill: ${colors.primary};
  }
  ${media.mobile(css`
    margin-right: 10px;
    margin-bottom: 10px;
  `)}
`;

const Inner = styled.div<{ variant?: ContactsIconType }>`
  display: flex;
  flex-direction: column;
  min-height: 75px;
  justify-content: space-around;
  position: relative;
`;

export const StyledLink = styled(Link)`
  font-size: 24px;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

export default ContactsBlock;
