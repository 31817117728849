import styled from 'styled-components';

export const Title = styled.h2`
  display: block;
  font-size: 36px;
  line-height: 100%;
  font-weight: 600;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
`;

export const Note = styled.span`
  display: block;
  font-size: 14px;
  line-height: 42px;
  margin-left: 25px;
`;
