import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import Link from '@/components/Link';
import { PlainPicture } from '@/components/Picture';
import { CatalogType } from '@/typings/model';
import { CatalogCardVariant } from '@/typings/common';

type Props = CatalogType & {
  className?: string;
};

function CatalogCard({ className, ...card }: Props) {
  const { title, image, url, mode } = card;
  return (
    <StyledLink to={url} className={className}>
      <Content mode={mode}>
        {image ? (
          <PictureWrapper>
            <CardPicture
              loading="lazy"
              {...convertThumbnailToPictureProps(image[mode])}
              alt={title}
            />
          </PictureWrapper>
        ) : null}
        <Title>{title}</Title>
      </Content>
    </StyledLink>
  );
}

const StyledLink = styled(Link)``;

const Title = styled.h2`
  position: relative;
  font-weight: 500;
`;

const PictureWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: -webkit-linear-gradient(
      to bottom,
      rgb(230, 231, 230),
      transparent 30%
    );
  }
`;

const CardPicture = styled(PlainPicture)`
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: all 0.45s ease-in-out 0s;
    object-position: bottom;
`;

const Content = styled.div<{ mode?: CatalogCardVariant }>`
  position: relative;
  display: flex;
  padding: 25px;
  height: ${({ mode }) => (mode === 'portrait' ? '640px' : '310px')};
  max-width: ${({ mode }) =>
    mode === 'landscape' ? '700px' : mode === 'portrait' ? '420px' : '340px'};
  &:hover {
    color: ${colors.primary};
  }

  &:hover ${CardPicture} {
    img {
      transform: scale(1.1);
    }
  }
`;

export default CatalogCard;
