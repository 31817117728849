import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { ButtonLink } from '@/components/Button';
import { media } from '@/utils/mixin';
import { DeliveryCardType } from '@/typings/model';

type Props = DeliveryCardType & {
  className?: string;
};

function DeliveryCard({ className, ...card }: Props) {
  const { title, text, button, prices } = card;
  return (
    <Container className={className}>
      {title ? <Title>{title}</Title> : null}
      <Content>
        <ContentBlock>
          {text ? <Text dangerouslySetInnerHTML={{ __html: text }} /> : null}
          {prices?.map((option) => (
            <ServiceWrapper key={option.name}>
              <Service>{option.name}</Service>
              <Price free={option.price === 'Бесплатно'}>{option.price}</Price>
            </ServiceWrapper>
          ))}
        </ContentBlock>
        {button && button.label ? (
          <ButtonWrapper>
            <CardLink
              variant="outlined-primary-w100"
              href={button.link ?? undefined}
              target={button.isNewTab ? '_blank' : '_self'}
            >
              {button.label}
            </CardLink>
          </ButtonWrapper>
        ) : null}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  padding: 30px;
  background: ${colors.white};
  border: 1px solid ${colors.gray100};
`;

const Title = styled.h3`
  display: block;
  font-size: 24px;
  line-height: 100%;
  font-weight: 500;
  margin-bottom: 20px;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.laptop(
    css`
      flex-direction: column;
      align-items: normal;
    `
  )};
`;

const ContentBlock = styled.div`
  margin-right: 40px;
  width: 100%;
  ${media.laptop(
    css`
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    `
  )}
`;

const Text = styled.div`
  display: block;
  font-size: 13px;
  line-height: 20px;
  p {
    margin: 10px 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  strong {
    font-size: 24px;
    font-weight: 500;
  }
`;

const ServiceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Service = styled.span`
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin-right: 20px;
`;

const Price = styled.span<{ free: boolean }>`
  display: block;
  font-weight: 500;
  font-size: 24px;
  color: ${(props) => (props.free ? `${colors.primary}` : `${colors.black}`)};
`;

const ButtonWrapper = styled.div`
  ${media.laptop(
    css`
      width: 100%;
    `
  )}
`;

const CardLink = styled(ButtonLink)`
  height: auto;
  line-height: 20px;
  padding: 6px 20px 8px;
  font-size: 14px;
  font-weight: 400;
`;

export default DeliveryCard;
