import React from 'react';
import styled, { css } from 'styled-components';

import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { PlainPicture } from '@/components/Picture';
import Placeholder from '@/assets/images/placeholder.png';
import { StringFieldType } from '@/typings/common';

type Props = {
  image: ThumbnailType;
  title: StringFieldType;
  link: StringFieldType;
};

function SamovarkinHomeContentCard({ image, title, link }: Props) {
  return (
    <Content hasLink={!!link}>
      {image ? (
        <ImageWrapper>
          <PostCover {...convertThumbnailToPictureProps(image)} />
        </ImageWrapper>
      ) : (
        <ImageWrapper>
          <PostCover src={Placeholder} />
        </ImageWrapper>
      )}
      <Title>{title}</Title>
    </Content>
  );
}

const ImageWrapper = styled.div`
  height: 190px;
  width: 100%;
`;

const PostCover = styled(PlainPicture)`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
`;

const Title = styled.span`
  line-height: 26px;
  font-weight: 500;
  margin-top: 15px;
`;

const Content = styled.div<{ hasLink: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 5px;
  color: ${colors.black};
  ${({ hasLink }) =>
    hasLink
      ? css`
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          &:hover ${Title} {
            color: ${colors.primary};
          }
          &:hover {
            text-decoration: underline;
          }
        `
      : ''}
`;

export default SamovarkinHomeContentCard;
