import React from 'react';

import ContentContainer from '@/components/ContentContainer';
import CreditOptionsTable from '@/components/CreditOptionsTable';
import { PaymentPageType } from '@/typings/model';
import useSettingItem from '@/hooks/useSettingItem';
import useCurrentPage from '@/hooks/useCurrentPage';
import TextHtml from '@/components/TextHTML';

import { TitleWrapper, Title, Note } from '../Payment.style';

function CreditOptionsSection() {
  const creditPrices = useSettingItem('PRICES_CREDIT');
  const page = useCurrentPage<PaymentPageType>();
  const pageFields = page?.templateFields;

  return (
    <ContentContainer>
      <TitleWrapper>
        <Title>{pageFields?.rassrochkaTitle}</Title>
        <Note>{pageFields?.rassrochkaNote}</Note>
      </TitleWrapper>
      {pageFields?.rassrochkaContent ? (
        <TextHtml text={pageFields.rassrochkaContent} />
      ) : null}
      <CreditOptionsTable options={creditPrices} />
    </ContentContainer>
  );
}

export default CreditOptionsSection;
