import React from 'react';
import styled from 'styled-components';
import { YMaps, Map, Placemark, ZoomControl } from 'react-yandex-maps';

import { Nullable } from '@tager/web-core';

type Coordinates = Array<number>;

type Props = {
  center: Nullable<Coordinates>;
  zoom?: number;
  pins?: Array<{
    coordinates: Coordinates;
    hint?: string;
    balloon?: string;
  }>;
};

const defaultCenter = [53.923, 27.443];

function YandexMap({ center, zoom = 9, pins }: Props) {
  return (
    <Content>
      <YMaps>
        <Map
          width={'100%'}
          height={'100%'}
          state={{ center: center || defaultCenter, zoom }}
        >
          <ZoomControl />
          {pins?.map((pin) => (
            <Placemark
              key={pin.coordinates.join('_')}
              modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
              geometry={pin.coordinates}
              options={{ preset: 'islands#redDotIcon' }}
              properties={{
                hintContent: pin.hint,
                balloonContent: pin.balloon,
              }}
            />
          ))}
        </Map>
      </YMaps>
    </Content>
  );
}

const Content = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
`;

export default YandexMap;
