import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import PaymentCard from '@/components/PaymentCard';
import { PaymentPageType } from '@/typings/model';
import useCurrentPage from '@/hooks/useCurrentPage';

import { TitleWrapper, Title } from '../Payment.style';

function PaymentCardsSection() {
  const page = useCurrentPage<PaymentPageType>();
  const pageFields = page?.templateFields;
  return (
    <ContentContainer>
      <TitleWrapper>
        <Title>{pageFields?.cardsTitle}</Title>
      </TitleWrapper>
      <Text>{pageFields?.cardsText}</Text>
      <Inner>
        {pageFields?.cardsItems?.map((cardItem, index) => (
          <StyledPaymentCard key={index} {...cardItem} />
        ))}
      </Inner>
    </ContentContainer>
  );
}

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px -15px 0;
  ${media.tabletLarge(
    css`
      display: block;
    `
  )}
`;

const Text = styled.div`
  display: block;
  font-size: 24px;
  line-height: 100%;
  font-weight: 500;
`;

const StyledPaymentCard = styled(PaymentCard)`
  padding: 15px;
  width: 50%;
  ${media.tabletLarge(
    css`
      width: auto;
    `
  )}
`;

export default PaymentCardsSection;
