import React from 'react';
import styled from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import PriceCard from '@/components/PriceCard';
import { colors } from '@/constants/theme';

import { primaryPrice, defaultPrice } from '../DefaultHome.constants';

function BelmebelHomePrices() {
  return (
    <ContentContainer>
      <Wrapper>
        <Title>Как формируется цена в обычном магазине</Title>
        <Row>
          {defaultPrice.map((price, index) => (
            <StyledPriceCard key={index} {...price} />
          ))}
        </Row>
        <Disclaimer>
          Рассчет на примере 3-х местного дивана Манчестер в официальном
          магазине "ХК Пинскдрев"
        </Disclaimer>
      </Wrapper>
      <Wrapper>
        <StyledTitle>Как формируется цена у нас</StyledTitle>
        <Row>
          {primaryPrice.map((price, index) => (
            <StyledPriceCard key={index} {...price} />
          ))}
        </Row>
        <Disclaimer>
          Рассчет на примере 3-х местного дивана Манчестер при заказе у нас на
          сайте
        </Disclaimer>
      </Wrapper>
    </ContentContainer>
  );
}

const Wrapper = styled.div`
  margin-top: 40px;
  &:first-child {
    margin-top: 0;
  }
`;

const Title = styled.h3`
  display: block;
  font-size: 26px;
  line-height: 100%;
  font-weight: 600;
`;

const StyledTitle = styled(Title)`
  color: ${colors.primary};
`;

const Disclaimer = styled.p`
  display: block;
  color: #aaaaaa;
  font-size: 12px;
  font-style: italic;
  margin-top: 15px;
`;

const Row = styled.div`
  display: flex;
  margin: 20px -10px;
`;

const StyledPriceCard = styled(PriceCard)`
  margin: 0 10px;
`;

export default BelmebelHomePrices;
