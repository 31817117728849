import React from 'react';
import styled from 'styled-components';

import DeliveryMapAndConditionsSection from './components/DeliveryMapAndConditionsSection';
import DeliveryTitleSection from './components/DeliveryTitleSection';
import DeliveryPricesCardSection from './components/DeliveryPricesCardSection';

function Delivery() {
  return (
    <Container>
      <DeliveryTitleSection />
      <Wrapper>
        <DeliveryPricesCardSection />
      </Wrapper>
      <Wrapper>
        <DeliveryMapAndConditionsSection />
      </Wrapper>
    </Container>
  );
}

const Container = styled.div``;

const Wrapper = styled.div`
  margin-bottom: 65px;
`;

export default Delivery;
