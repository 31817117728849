import React from 'react';
import styled from 'styled-components';

import ContactsTitleSection from './components/ContactsTitleSection';
import ContactsMapSection from './components/ContactsMapSection';
import ContactsMessengersSection from './components/ContactsMessengersSection';
import ContactsInfoSection from './components/ContactsInfoSection';

function DefaultContacts() {
  return (
    <Container>
      <ContactsTitleSection />
      <Wrapper>
        <ContactsMessengersSection />
      </Wrapper>
      <Wrapper>
        <ContactsMapSection />
      </Wrapper>
      <Wrapper>
        <ContactsInfoSection />
      </Wrapper>
    </Container>
  );
}

export default DefaultContacts;

const Container = styled.div``;

const Wrapper = styled.div`
  margin-bottom: 50px;
`;
