import React from 'react';
import styled from 'styled-components';

import { FagPageType } from '@/typings/model';
import useCurrentPage from '@/hooks/useCurrentPage';
import ContentContainer from '@/components/ContentContainer';
import FaqQuestion from '@/components/FaqQuestion';

function FaqQuestionsSection() {
  const page = useCurrentPage<FagPageType>();
  const pageFields = page?.templateFields;

  return (
    <ContentContainer>
      {pageFields?.sections?.map((section, index) => (
        <Inner key={index}>
          <Title>{section?.title}</Title>
          {section?.questions?.map((question, index) => (
            <FaqQuestion key={index} {...question} />
          ))}
        </Inner>
      ))}
    </ContentContainer>
  );
}

const Inner = styled.div`
  margin-top: 40px;
  max-width: 1250px;
  &:first-child {
    margin-top: 0;
  }
`;

const Title = styled.h2`
  display: block;
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 15px;
`;

export default FaqQuestionsSection;
