import React from 'react';

import ContentContainer from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { RassrochkaPageType } from '@/typings/model';
import TextHtml from '@/components/TextHTML';
import { BreadcrumbType } from '@/components/Breadcrumbs/Breadcrumbs';
import PageHeader from '@/components/PageHeader/PageHeader';

function PaymentTitleSection() {
  const page = useCurrentPage<RassrochkaPageType>();
  if (!page) return null;

  const BREADCRUMB_LIST: Array<BreadcrumbType> = [];

  if (page) {
    BREADCRUMB_LIST.push({
      label: page.title,
      to: page.path,
    });
  }

  return (
    <ContentContainer>
      <PageHeader title={page.title} breadcrumbs={BREADCRUMB_LIST} />
      {page.body ? <TextHtml text={page.body} /> : null}
    </ContentContainer>
  );
}

export default PaymentTitleSection;
