import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import chunk from 'lodash/chunk';

import ContentContainer from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { CatalogSamovarkinType, SamovarkinHomePageType } from '@/typings/model';
import { media } from '@/utils/mixin';
import Link from '@/components/Link';

import { Title } from '../SamovarkinHome.styles';

import SamovarkinHomeCatalogCard from './components/SamovarkinHomeCatalogCard';

function SamovarkinHomeCatalog() {
  const page = useCurrentPage<SamovarkinHomePageType>();
  const templateFields = page?.templateFields;
  const catalogItems = templateFields?.catalogItems;

  const landscapeAndSquareItemList = useMemo(() => {
    if (!catalogItems) {
      return [];
    }

    function hasNotChildren(catalogItem: CatalogSamovarkinType): boolean {
      return !catalogItem.children.length;
    }

    return { ...chunk(catalogItems.filter(hasNotChildren), 2) };
  }, [catalogItems]);

  const portraitItem = catalogItems?.find(
    (portraitItem) => !!portraitItem.children.length
  );

  return (
    <ContentContainer>
      <Container>
        <Title>{templateFields?.catalogTitle}</Title>
        {catalogItems && !!catalogItems.length ? (
          <Row>
            <SquareAndLandscapeWrapper>
              <LandscapeWrapper>
                {landscapeAndSquareItemList[0].map((catalogItem) => (
                  <>
                    {catalogItem.link ? (
                      <StyledLink to={catalogItem.link} key={catalogItem.title}>
                        <StyledSamovarkinCatalogCard
                          {...catalogItem}
                          mode="landscape"
                        />
                      </StyledLink>
                    ) : (
                      <StyledSamovarkinCatalogCard
                        {...catalogItem}
                        mode="landscape"
                        key={catalogItem.title}
                      />
                    )}
                  </>
                ))}
              </LandscapeWrapper>
              <SquareWrapper>
                {landscapeAndSquareItemList[1].map((catalogItem) => (
                  <>
                    {catalogItem.link ? (
                      <StyledLink to={catalogItem.link} key={catalogItem.title}>
                        <StyledSamovarkinCatalogCard
                          {...catalogItem}
                          mode="square"
                        />
                      </StyledLink>
                    ) : (
                      <StyledSamovarkinCatalogCard
                        {...catalogItem}
                        mode="square"
                        key={catalogItem.title}
                      />
                    )}
                  </>
                ))}
              </SquareWrapper>
            </SquareAndLandscapeWrapper>
            <PortraitWrapper>
              {portraitItem ? (
                <PortraitSamovarkinCatalogCard
                  {...portraitItem}
                  mode="portrait"
                />
              ) : null}
            </PortraitWrapper>
          </Row>
        ) : null}
      </Container>
    </ContentContainer>
  );
}

const StyledLink = styled(Link)``;

const Container = styled.div`
  margin-top: 40px;
`;

const Row = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  margin-top: 30px;
`;

const SquareAndLandscapeWrapper = styled.div`
  display: flex;
  flex: 0 0 80%;
  margin: -10px;
  ${media.laptop(
    css`
      flex: 0 0 75%;
    `
  )};
`;

const StyledSamovarkinCatalogCard = styled(SamovarkinHomeCatalogCard)`
  margin: 10px;
`;

const LandscapeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 calc(45% - 20px);
`;

const SquareWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 calc(30% - 20px);
  ${media.laptop(
    css`
      flex: 1 0 calc(25% - 20px);
    `
  )};
`;

const PortraitSamovarkinCatalogCard = styled(SamovarkinHomeCatalogCard)``;

const PortraitWrapper = styled.div`
  flex: 0 0 20%;
  display: flex;
  ${PortraitSamovarkinCatalogCard} {
    width: 100%;
  }
  ${media.laptop(
    css`
      flex: 0 0 25%;
    `
  )};
`;

export default SamovarkinHomeCatalog;
