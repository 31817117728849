import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

type Props = {
  title: string;
  itemProp?: boolean;
  note?: string | number;
  isNoteAtTop?: boolean;
};

function PageTitle({ itemProp, title, note, isNoteAtTop }: Props) {
  const h1Element = itemProp ? (
    <Title itemprop="name">{title}</Title>
  ) : (
    <Title>{title}</Title>
  );

  if (!note) {
    return h1Element;
  }

  return (
    <TitleWrapper isNoteAtTop={isNoteAtTop}>
      {h1Element}
      {note ? <Note>{note}</Note> : null}
    </TitleWrapper>
  );
}

const TitleWrapper = styled.div<{ isNoteAtTop?: boolean }>`
  margin-right: 50px;
  ${({ isNoteAtTop }) =>
    isNoteAtTop
      ? css`
          display: flex;
          align-items: center;
        `
      : ''};
`;

const Title = styled.h1<{ itemprop?: string }>`
  display: block;
  font-size: 36px;
  line-height: 100%;
  margin: 0 10px 0 0;
`;

const Note = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  color: ${colors.gray300};
  margin-top: 10px;
`;

export default PageTitle;
