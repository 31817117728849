import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import Placeholder from '@/assets/images/placeholder.png';
import { BelsexHomePageType, HeroSliderType } from '@/typings/model';
import useCurrentPage from '@/hooks/useCurrentPage';
import { colors } from '@/constants/theme';
import ContentContainer from '@/components/ContentContainer';
import { PlainPicture } from '@/components/Picture';
import Link from '@/components/Link';
import SwiperBullets from '@/modules/Home/HomeProject/components/SwiperBullets';

type Props = {
  slider: HeroSliderType;
};

function Slider({ slider }: Props) {
  return (
    <SliderWrapper>
      {slider.image ? (
        <MainStyledPlainPicture
          {...convertThumbnailToPictureProps(slider.image)}
        />
      ) : (
        <MainStyledPlainPicture src={Placeholder} />
      )}
      <ContentContainer>
        <InfoWrapper>
          {slider.title ? <Title>{slider.title}</Title> : null}
          {slider.text ? <Description>{slider.text}</Description> : null}
          {slider.link ? (
            <Link to={slider.link}>
              <Button>{slider.buttonLabel ?? 'Подробнее'}</Button>
            </Link>
          ) : null}
        </InfoWrapper>
      </ContentContainer>
    </SliderWrapper>
  );
}

function BelsexHomeBanners() {
  const page = useCurrentPage<BelsexHomePageType>();
  const templateFields = page?.templateFields;
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [distanceToTop, setDistanceToTop] = useState<number>(214);
  const innerRef = useRef<HTMLDivElement>(null);
  const sliderPagination = useRef<HTMLInputElement>(null);

  function getDistanceToTop() {
    return innerRef.current?.getBoundingClientRect().top ?? 0;
  }

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    setDistanceToTop(getDistanceToTop() ?? 0);
  }, []);

  return (
    <Container ref={innerRef} distance={distanceToTop}>
      <Inner>
        {isMounted ? (
          <SwiperBullets>
            {templateFields?.heroSlider && !!templateFields?.heroSlider.length
              ? templateFields?.heroSlider.map((slider, index) => (
                  <SwiperSlide key={index}>
                    <Slider slider={slider} />
                  </SwiperSlide>
                ))
              : null}
          </SwiperBullets>
        ) : (
          <>
            {templateFields?.heroSlider &&
            !!templateFields?.heroSlider.length &&
            templateFields?.heroSlider[0].image ? (
              <Slider slider={templateFields.heroSlider[0]} />
            ) : null}
          </>
        )}
        <ItemsPagination className="swiper-pagination" ref={sliderPagination} />
        {templateFields?.heroFeatures &&
        !!templateFields?.heroFeatures.length ? (
          <Icons>
            <ContentContainer>
              <IconsWrapper>
                {templateFields.heroFeatures.map((feature, index) => (
                  <IconInner key={index}>
                    <StyledPlainPicture
                      {...convertThumbnailToPictureProps(feature.icon)}
                    />
                    <IconInfo>
                      <IconTitle>{feature.title}</IconTitle>
                      <IconText>{feature.text}</IconText>
                    </IconInfo>
                  </IconInner>
                ))}
              </IconsWrapper>
            </ContentContainer>
          </Icons>
        ) : null}
      </Inner>
    </Container>
  );
}

const Container = styled.div<{ distance: number }>`
  position: relative;
  height: max(calc(100vh - ${({ distance }) => distance}px), 450px);
  max-height: 800px;
  color: ${colors.white};
  width: 100%;
`;

const anim = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const MainStyledPlainPicture = styled(PlainPicture)`
  position: absolute !important;
  z-index: -1;
  height: 100%;
`;

const Inner = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  ${MainStyledPlainPicture} {
    img {
      width: 100vw;
      min-width: 1260px;
      height: 100%;
      object-fit: cover;
    }
  }
  .swiper-container {
    height: 100%;
  }
  .swiper-pagination {
    display: flex;
    justify-content: center;
    height: 20px;
    bottom: 160px;
    ${media.laptop(
      css`
        bottom: 120px;
      `
    )}
  }
`;

const SliderWrapper = styled.div`
  display: flex;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(69, 45, 65, 0.7) 0%,
    rgba(69, 45, 65, 0.4) 100%
  );
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: calc(100% - 140px);
  width: 50%;
  margin-left: 100px;
  ${media.laptop(
    css`
      margin-left: 60px;
      height: calc(100% - 100px);
    `
  )}
`;

const Title = styled.span`
  font-size: 60px;
  text-shadow: 0 0 4px ${colors.white};
  ${media.laptop(
    css`
      font-size: 46px;
    `
  )}
`;

const Description = styled.p`
  font-size: 48px;
  margin-top: 20px;
  ${media.laptop(
    css`
      font-size: 38px;
      margin-top: 10px;
    `
  )}
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.white};
  border-radius: 5px;
  padding: 8px 30px;
  margin-top: 55px;
  color: ${colors.white};
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
  ${media.laptop(
    css`
      font-size: 14px;
      padding: 5px 15px;
      margin-top: 30px;
    `
  )}
`;

const ItemsPagination = styled.div`
  position: relative;
    .swiper-pagination-bullet {
      position: relative;
      max-width: 40px;
      flex: 1 1 auto;
      height: 4px;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.4);
      opacity: 1;
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
      ${media.laptop(
        css`
          max-width: 30px;
          margin-left: 10px;
        `
      )}
      &:before {
        position: absolute;
        left: 0;
        width: 0;
        content: '';
        height: 4px;
        background-color: ${colors.white};
        opacity: 1;
      }
    }
    .swiper-pagination-bullet-active {
      position: relative;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        will-change: transform;
        animation: ${anim} 4350ms linear;
      }
    }
  }
`;

const Icons = styled.div`
  position: relative;
  background: rgba(66, 34, 61, 0.8);
  bottom: 160px;
  z-index: 10;
  ${media.laptop(
    css`
      bottom: 120px;
    `
  )}
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 150px;
  height: 140px;
  ${media.laptop(
    css`
      margin: 0 100px;
      height: 100px;
    `
  )}
`;

const IconInner = styled.div`
  display: flex;
`;

const StyledPlainPicture = styled(PlainPicture)`
  display: flex;
  align-items: center;
  margin-right: 25px;
  max-height: 70px;
  max-width: 100px;
  ${media.laptop(
    css`
      margin-right: 20px;
      img {
        max-height: 50px;
        max-width: 70px;
      }
    `
  )}
`;

const IconInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.85);
`;

const IconTitle = styled.span`
  font-size: 34px;
  ${media.laptop(
    css`
      font-size: 24px;
    `
  )}
`;

const IconText = styled.span`
  margin-top: 5px;
  ${media.laptop(
    css`
      font-size: 14px;
    `
  )}
`;

export default BelsexHomeBanners;
