import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useGallery } from '@tager/web-components';
import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { ContactsPageType } from '@/typings/model';
import ContentContainer from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { PlainPicture } from '@/components/Picture';

function ContactsInfoSection() {
  const page = useCurrentPage<ContactsPageType>();
  const pageFields = page?.templateFields;

  const openGallery = useGallery();

  const imageList = useMemo(
    () =>
      pageFields?.gallery?.map((img) => ({
        url: img?.url ?? '',
      })) ?? [],
    [pageFields]
  );

  return (
    <ContentContainer>
      <Inner>
        <Cell>
          {pageFields?.gallery?.map((img, index) => (
            <PictureWrapper
              key={index}
              onClick={() => openGallery({ imageList, initialIndex: index })}
            >
              <PlainPicture
                {...convertThumbnailToPictureProps(img)}
                alt={'document'}
              />
            </PictureWrapper>
          ))}
        </Cell>
        <Cell>
          <Title>{pageFields?.legalTitle}</Title>
          <Text>{pageFields?.legalText}</Text>
        </Cell>
      </Inner>
    </ContentContainer>
  );
}

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Cell = styled.div`
  flex-basis: calc(50% - 15px);
`;

const PictureWrapper = styled.div`
  transition: all 0.45s ease-in-out 0s;
  img {
    object-fit: cover;
    object-position: bottom;
    width: 100%;
  }
  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    opacity: 0.85;
    cursor: pointer;
  }
`;

const Title = styled.h3`
  font-size: 22px;
  line-height: 100%;
  font-weight: 500;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 26px;
  white-space: pre-line;
  margin-top: 10px;
`;

export default ContactsInfoSection;
