import React from 'react';
import styled from 'styled-components';

import BelsexHomeBanners from './components/BelsexHomeBanners';

function BelsexHome() {
  return (
    <Container>
      <BelsexHomeBanners />
    </Container>
  );
}

const Container = styled.div``;

export default BelsexHome;
