import React, { useState } from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';
import { ReactComponent as ArrowIcon } from '@/assets/svg/dropdown_arrow.svg';
import TextHtml from '@/components/TextHTML';
import { StringFieldType } from '@/typings/common';

type Props = {
  question?: StringFieldType;
  answer?: StringFieldType;
  className?: string;
};

function FaqQuestion({ question, answer, className }: Props) {
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <Container
      hasAnswer={!!answer}
      className={className}
      opened={opened}
      onClick={
        answer
          ? (e) => {
              if ((e.target as HTMLElement).tagName !== 'A') {
                setOpened(!opened);
              }
            }
          : undefined
      }
    >
      <Question>{question}</Question>
      <IconWrapper>
        <ArrowIcon />
      </IconWrapper>
      {opened && answer ? <TextHtml text={answer} /> : null}
    </Container>
  );
}

function getHoverColor(props: {
  opened?: boolean;
  hasAnswer: boolean;
}): string {
  if (!props.hasAnswer) return 'black';
  return props.opened ? `${colors.primary100}` : `${colors.primary}`;
}

const Question = styled.div`
  padding: 15px 0;
  font-size: 16px;
  font-weight: 500;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 15px;
`;

const Container = styled.div<{ opened: boolean; hasAnswer: boolean }>`
  position: relative;
  border-top: 1px solid ${colors.gray100};
  padding-right: 30px;
  min-height: 48px;
  cursor: ${(props) => (props.hasAnswer ? 'pointer' : 'default')};

  ${Question} {
    color: ${(props) =>
      props.opened ? `${colors.primary}` : `${colors.black}`};
  }

  ${IconWrapper} svg {
    fill: ${(props) => (props.opened ? `${colors.primary}` : '#a8a8a8')};
    transform: ${(props) => (props.opened ? 'rotate(180deg)' : '')};
  }

  &:hover {
    ${Question}, ${IconWrapper} svg {
      color: ${(props) => getHoverColor(props)};
      fill: ${(props) => getHoverColor(props)};
    }
  }

  &:last-child {
    border-bottom: 1px solid ${colors.gray100};
  }
`;

export default FaqQuestion;
