import React from 'react';
import styled from 'styled-components';

import { useGallery } from '@tager/web-components';
import { MapFieldValueType } from '@tager/web-core';

import { ContactsPageType } from '@/typings/model';
import useCurrentPage from '@/hooks/useCurrentPage';
import ContentContainer from '@/components/ContentContainer';
import YandexMap from '@/components/YandexMap';
import TextWithIcon from '@/components/TextWithIcon';
import { colors } from '@/constants/theme';

function getCoordinates(coordinates?: MapFieldValueType): Array<number> | null {
  if (!coordinates) return null;
  return Object.values(coordinates);
}

function ContactsTitleSection() {
  const page = useCurrentPage<ContactsPageType>();
  const pageFields = page?.templateFields;
  const coordinates = getCoordinates(pageFields?.map);

  const openGallery = useGallery();

  return (
    <Container>
      <YandexMap
        center={coordinates}
        zoom={15}
        pins={coordinates ? [{ coordinates }] : undefined}
      />
      <ContentContainer>
        <Inner>
          <Wrapper>
            <Title>{pageFields?.mapTitle}</Title>
            <SubTitle>{pageFields?.mapShopTitle}</SubTitle>
            {pageFields?.mapAddress ? (
              <>
                <StyledTextWithIcon
                  text={pageFields.mapAddress}
                  icon="location"
                />
                {pageFields?.gallery?.[0] ? (
                  <FloorPlan
                    onClick={() =>
                      openGallery({
                        imageList: [
                          { url: pageFields?.gallery?.[0]?.url ?? '' },
                        ],
                      })
                    }
                  >
                    Схема этажа
                  </FloorPlan>
                ) : null}
              </>
            ) : null}
            {pageFields?.mapWorkTime ? (
              <StyledTextWithIcon text={pageFields.mapWorkTime} icon="clock" />
            ) : null}
            <SubTitle>{pageFields?.mapHowToTitle}</SubTitle>
            {pageFields?.mapHowToText ? (
              <Text
                dangerouslySetInnerHTML={{ __html: pageFields.mapHowToText }}
              />
            ) : null}
          </Wrapper>
        </Inner>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  padding: 40px 0;
  position: relative;
`;

const Inner = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  width: 570px;
  background: ${colors.white};
  padding: 35px;
  position: relative;
`;

const Title = styled.h2`
  display: block;
  font-size: 36px;
  line-height: 100%;
  font-weight: 600;
`;

const SubTitle = styled.span`
  display: block;
  font-size: 22px;
  font-weight: 500;
  margin: 35px 0 20px;
`;

const StyledTextWithIcon = styled(TextWithIcon)`
  align-items: flex-start;
  margin-top: 20px;
  font-size: 18px;
  line-height: 24px;
  &:first-of-type {
    margin-top: 0;
  }
  svg {
    margin: 5px 15px 0 0;
  }
`;

const FloorPlan = styled.div`
  color: ${colors.primary};
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  margin: 10px 0 0 30px;
  &:hover {
    text-decoration: none;
  }
`;

const Text = styled.span`
  display: block;
  font-size: 14px;
  line-height: 22px;
`;

export default ContactsTitleSection;
