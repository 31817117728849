import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import { PlainPicture } from '@/components/Picture';
import { media } from '@/utils/mixin';
import { BannersType } from '@/typings/model';

type Props = BannersType & {
  className?: string;
};

function SidebarBanner({ className, ...banner }: Props) {
  const { title, text, image, url } = banner;
  return (
    <StyledLink to={url} className={className}>
      <Container className={className}>
        <Cell>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </Cell>
        <Cell>
          {image ? (
            <PictureWrapper>
              <PlainPicture
                loading="lazy"
                {...convertThumbnailToPictureProps(image)}
                alt={title}
              />
            </PictureWrapper>
          ) : null}
        </Cell>
      </Container>
    </StyledLink>
  );
}

const StyledLink = styled(Link)``;

const Container = styled.div`
  background: #f0f0f0;
  display: flex;
  align-items: center;
  padding: 10px 25px;
  justify-content: space-between;

  &:hover {
    color: ${colors.primary};
  }
`;

const Cell = styled.div`
  ${media.mobileLarge(
    css`
      &:first-child {
        margin-right: 10px;
      }
    `
  )}
`;

const PictureWrapper = styled.div`
  img {
    max-height: 136px;
  }
`;

const Title = styled.span`
  font-size: 30px;
  font-weight: 600;
  white-space: pre-line;
  display: block;
  margin-bottom: 10px;
  ${media.mobileMedium(
    css`
      font-size: 24px;
      font-weight: 500;
    `
  )}
`;

const Text = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  white-space: pre-line;
  ${media.mobileMedium(
    css`
      font-size: 12px;
    `
  )}
`;

export default SidebarBanner;
