import React from 'react';

import { getCurrentProject } from '@/utils/common';

import DefaultContacts from './projects/DefaultContacts';
import SamovarkinContacts from './projects/SamovarkinContacts';

function Contacts() {
  const project = getCurrentProject();

  switch (project) {
    case 'SAMOVARKIN':
      return <SamovarkinContacts />;
    default:
      return <DefaultContacts />;
  }
}

export default Contacts;
