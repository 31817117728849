import React from 'react';

import { PageFullType } from '@tager/web-modules';

import ContentContainer from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import TextHtml from '@/components/TextHTML';

function HomeSeo() {
  const page = useCurrentPage<PageFullType>();
  return page?.body ? (
    <ContentContainer>
      <TextHtml text={page.body} />
    </ContentContainer>
  ) : null;
}

export default HomeSeo;
