import React from 'react';
import styled from 'styled-components';

import PaymentEripSection from './components/PaymentEripSection';
import PaymentMethodSection from './components/PaymentMethodSection';
import PaymentCardsSection from './components/PaymentCardsSection';
import DocumentCardsSection from './components/DocumentCardsSection';
import CreditOptionsSection from './components/CreditOptionsSection';
import PaymentTitleSection from './components/PaymentTitleSection';

function Payment() {
  return (
    <Container>
      <PaymentTitleSection />
      <Wrapper>
        <PaymentMethodSection />
      </Wrapper>
      <Wrapper>
        <DocumentCardsSection />
      </Wrapper>
      <Wrapper id="cards">
        <PaymentCardsSection />
      </Wrapper>
      <Wrapper id="rassrochka">
        <CreditOptionsSection />
      </Wrapper>
      <Wrapper id="erip">
        <PaymentEripSection />
      </Wrapper>
    </Container>
  );
}

const Container = styled.div``;

const Wrapper = styled.div`
  margin-bottom: 60px;
`;

export default Payment;
